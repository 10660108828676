import { Alert, AlertColor, Button, Snackbar } from "@mui/material";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../Hooks";
import { getShwoAlert } from "../../store/user-process/selector";
import { setShowAllert } from "../../store/user-process/user-process";

export default function ShowAlert(){

  const dispatch = useAppDispatch();

  const alert = useAppSelector(getShwoAlert);

  useEffect(() => {
    const timeoutId =  setTimeout(()=>{
      dispatch(setShowAllert({open:false}));

    },alert.time == undefined ? 5000 : alert.time );
    return () => clearTimeout(timeoutId);
  },[alert.open]);

  const handleClose = (event:any, reason:any) => {
    dispatch(setShowAllert({open:false}));
  };

  return (
    <>
      <Snackbar open={alert.open} sx={{ height: "100%" }}  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}  onClose={handleClose}>
        <Alert severity={alert.type as AlertColor}>
          {alert.message}
        </Alert>
      </Snackbar>
    </>
  );
}

