import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from '../../title/title';
import { IReadingHistory } from '../../../types/type-integration/reading-history';
import dayjs from 'dayjs';

type DataValue = {
  data: IReadingHistory[]
}

export default function HistoryIndicationTable(readingHistory:DataValue) {
  return (
    <React.Fragment>
      <Title>Потребление</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell align="center" >Дата</TableCell>
            <TableCell align="center" >Тип ПУ</TableCell>
            <TableCell align="center" >Пункт передачи показаний</TableCell>
            <TableCell align="center" >Показания</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {readingHistory.data.map((row) => (
            <TableRow key={row.readings}>
              <TableCell align="center"  >{dayjs(row.readingsDate).format('DD-MM-YYYY')}</TableCell>
              <TableCell align="center" >{row.typePu}</TableCell>
              <TableCell align="center" >{row.organization}</TableCell>
              <TableCell align="center" >{row.readings}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {/* <Link color="primary" href="#" onClick={preventDefault} sx={{ mt: 3 }}>
        See more orders
      </Link> */}
    </React.Fragment>
  );
}
