import * as React from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import Textarea from "@mui/joy/Textarea";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import { IUserMessage } from "../../../types/user-info/user-message";
import { IShowAlert, IUserInfo } from "../../../types/user-info/user-info";
import { useAppDispatch } from "../../../Hooks";
import { addUserMessage, setShowAllert } from "../../../store/user-process/user-process";
import { User } from "../../../types/user-info/user-request";
import { CreateMessage } from "../../../services/api-actions/personal-user-request-action";
import byteConverter from "../../../utils/byteConverter";
import { AlertType } from "../../../enums/enum";
import { List, Stack } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import useWindowDimensions from "../../../Hooks/windowDimensions";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export type MessageInputProps = {
  textAreaValue: string;
  setTextAreaValue: (value: string) => void;
  userInfo: IUserInfo;
  requestId: string;
};

export default function MessageInput(props: MessageInputProps) {
  const dispatch = useAppDispatch();
  const { height, width } = useWindowDimensions();
  const { textAreaValue, setTextAreaValue, userInfo, requestId } = props;
  const textAreaRef = React.useRef<HTMLDivElement>(null);
  const handleClick = () => {
    if (textAreaValue.trim() !== "") {
      dispatch(
        CreateMessage({
          RequestId: requestId,
          Message: textAreaValue,
          File: file,
        })
      );
      dispatch(
        addUserMessage({
          createdDate: new Date(),
          message: textAreaValue,
          user: { fio: userInfo.fio, id: userInfo.userId } as User,
          fileName: file?.name,
        } as IUserMessage)
      );
      setTextAreaValue("");
      setFile(undefined);
    } else {
      dispatch(
        setShowAllert({
          open: true,
          message: "Введите текст сообщения для отправки",
          type: AlertType.error,
        } as IShowAlert)
      );
    }
  };
  const [file, setFile] = React.useState<File>();
  const handleFileChange = (e: any) => {
    if (e.target.files[0] == undefined) return;
    if (e.target.files[0].name.includes(".exe")) {
      dispatch(
        setShowAllert({
          open: true,
          message: "Нельзя загружать файл тип .exe",
          type: AlertType.error,
        } as IShowAlert)
      );
      return;
    }
    if (Number(byteConverter(e.target.files[0].size, 2, "MB").replace("MB", "")) > 15) {
      dispatch(
        setShowAllert({
          open: true,
          message: "Загружаемый файл должен быть меньше 15 MB",
          type: AlertType.error,
        } as IShowAlert)
      );
      return;
    }
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };
  const handleFileRemove = (e: any) => {
    setFile(undefined);
  };
  return (
    <Box sx={{ px: 2, pb: 3 }}>
      <FormControl>
        <Textarea
          placeholder="Введите сообщение…"
          aria-label="сообщение"
          ref={textAreaRef}
          onChange={(e) => {
            setTextAreaValue(e.target.value);
          }}
          value={textAreaValue}
          minRows={3}
          maxRows={10}
          endDecorator={
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              flexGrow={1}
              sx={{
                py: 1,
                pr: 1,
                borderTop: "1px solid",
                borderColor: "divider",
              }}
            >
              <Button size="sm" color="primary" sx={{ alignSelf: "center", borderRadius: "sm" }} endDecorator={<SendRoundedIcon />} onClick={handleClick}>
                Отправить
              </Button>
              <Button size="sm" component="label">
                {width >= 800 ? "Добавить файл" : <AttachFileIcon />}
                <input type="file" hidden onChange={(e) => handleFileChange(e)} />
              </Button>
              <Button
                size="sm"
                color="primary"
                sx={{ alignSelf: "center", borderRadius: "sm" }}
                //endDecorator={<SendRoundedIcon />}
                onClick={() => {
                  window.history.back();
                  window.close();
                }}
              >
                {width >= 800 ? "Вернуться назад" : <ArrowBackIcon />}
              </Button>
            </Stack>
          }
          onKeyDown={(event) => {
            if (event.key === "Enter" && (event.metaKey || event.ctrlKey)) {
              handleClick();
            }
          }}
          sx={{
            "& textarea:first-of-type": {
              minHeight: 72,
            },
          }}
        />
      </FormControl>
      {file != undefined && (
        <List>
          <>
            <div style={{ display: "flex" }}>
              <p>{file.name}</p>
              <Button size="sm" color="danger" style={{ marginLeft: "10px" }} sx={{ alignSelf: "center", borderRadius: "sm" }} onClick={() => handleFileRemove(file.name)}>
                <DeleteIcon />
              </Button>
            </div>
          </>
        </List>
      )}
    </Box>
  );
}
