import * as React from "react";
import Box from "@mui/joy/Box";
import Sheet from "@mui/joy/Sheet";
import Stack from "@mui/joy/Stack";
import ChatBubble from "./ChatBubble";
import MessageInput from "./MessageInput";
import MessagesPaneHeader from "./MessagesPaneHeader";
import { useAppDispatch, useAppSelector } from "../../../Hooks";
import { GetUserRequestMessage, SetGrade } from "../../../services/api-actions/personal-user-request-action";
import { getUserInfo, getUserRequestMessage } from "../../../store/user-process/selector";
import { IUserMessage } from "../../../types/user-info/user-message";
import { Status, UserRole } from "../../../enums/enum";
import { Button, FormControl, FormLabel, Radio, RadioGroup } from "@mui/joy";
import { userIsRole } from "../../../utils/user-is-role";

type chatId = {
  chatId: string | undefined;
};

export default function MessagesPane(props: chatId) {
  const { chatId } = props;

  const dispatch = useAppDispatch();

  const [gradeSet, setGradeSet] = React.useState(false);
  const [grade, setGrade] = React.useState(0);
  const [chatMessages, setChatMessages] = React.useState(chatId);
  const [textAreaValue, setTextAreaValue] = React.useState("");
  const userMessage = useAppSelector(getUserRequestMessage);
  const userInfo = useAppSelector(getUserInfo);
  React.useEffect(() => {
    setChatMessages(chatId);
    dispatch(GetUserRequestMessage({ chatId: chatId }));
  }, [dispatch]);
  console.log(userMessage);
  return (
    <Sheet
      sx={{
        height: { xs: "calc(80dvh - var(--Header-height))", lg: "80dvh" },
        display: "flex",
        flexDirection: "column",
        backgroundColor: "background.level1",
      }}
    >
      <MessagesPaneHeader thema={userMessage != undefined ? userMessage[0]?.personalUserRequest?.subject.subject : ""} userInfo={userInfo} chatId={chatId} />
      {userMessage != undefined &&
        userMessage != null &&
        userMessage[0]?.personalUserRequest?.grade == null &&
        gradeSet == false &&
        !userIsRole(userInfo, UserRole.Operator) &&
        !userIsRole(userInfo, UserRole.OperatorAdmin) &&
        userMessage[0]?.personalUserRequest?.status.id === Status.Success && (
          <div style={{ marginLeft: "auto", marginRight: "auto" }}>
            <FormControl>
              <FormLabel>Оцените от 1 до 5 где 1 плохо 5 хорошо</FormLabel>
              <RadioGroup defaultValue="1" name="radio-buttons-group" sx={{ display: "block" }}>
                <Radio
                  value="1"
                  onChange={(e) => {
                    setGrade(Number(e.target.value));
                  }}
                  sx={{ mr: "10px" }}
                  label="1"
                  variant="outlined"
                />
                <Radio
                  value="2"
                  onChange={(e) => {
                    setGrade(Number(e.target.value));
                  }}
                  sx={{ mr: "10px" }}
                  label="2"
                  variant="outlined"
                />
                <Radio
                  value="3"
                  onChange={(e) => {
                    setGrade(Number(e.target.value));
                  }}
                  sx={{ mr: "10px" }}
                  label="3"
                  variant="outlined"
                />
                <Radio
                  value="4"
                  onChange={(e) => {
                    setGrade(Number(e.target.value));
                  }}
                  sx={{ mr: "10px" }}
                  label="4"
                  variant="outlined"
                />
                <Radio
                  value="5"
                  onChange={(e) => {
                    setGrade(Number(e.target.value));
                  }}
                  sx={{ mr: "10px" }}
                  label="5"
                  variant="outlined"
                />
              </RadioGroup>
            </FormControl>
            <Button
              size="sm"
              component="label"
              onClick={() => {
                if (chatId !== undefined) {
                  dispatch(SetGrade({ requestId: chatId, grade: grade }));
                  setGradeSet(true);
                }
              }}
            >
              Оценить
            </Button>
          </div>
        )}
      <Box
        sx={{
          display: "flex",
          flex: 1,
          minHeight: 0,
          px: 2,
          py: 3,
          overflowY: "scroll",
          flexDirection: "column-reverse",
        }}
      >
        <Stack spacing={2} justifyContent="flex-end">
          {userMessage?.map((message: IUserMessage, index: number) => {
            const isYou = message.user.id === userInfo.userId ? true : false;
            return (
              <Stack key={index} direction="row" spacing={2} flexDirection={isYou ? "row-reverse" : "row"}>
                <ChatBubble variant={isYou ? "sent" : "received"} sender={isYou ? "Вы" : message.user.fio} {...message} />
              </Stack>
            );
          })}
        </Stack>
      </Box>
      {userMessage != undefined &&
        userMessage != null &&
        userMessage[0]?.personalUserRequest?.status.id != Status.Cansel &&
        userMessage[0]?.personalUserRequest?.status.id != Status.Success && (
          <div>
            <MessageInput textAreaValue={textAreaValue} setTextAreaValue={setTextAreaValue} userInfo={userInfo} requestId={chatId == undefined ? "" : chatId} />
          </div>
        )}
    </Sheet>
  );
}
