import { Route, Routes } from "react-router-dom";
import { AppRoute } from "./enums/enum";
import AuthorizeRoute from "./Components/api-authorization/AuthorizeRoute";
import ApiAuthorzationRoutes from "./Components/api-authorization/ApiAuthorizationRoutes";
import Main from "./Page/Main/Main";
import Page404 from "./Page/page-404/page-404";
import HistoryIndication from "./Page/history-indication/history-indication";
import Lic from "./Page/lic/lic";
import { useAppDispatch } from "./Hooks";
import { GetUserInfo } from "./services/api-actions/user-action";
import AppRoutes from "./AppRoutes";
import React from "react";

function App(): JSX.Element {
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    dispatch(GetUserInfo());
  }, [dispatch]);
  console.log(process.env.NODE_ENV);
  console.log(process.env.REACT_APP_DEVELOP_MODE);
  return (
    <Routes>
      {AppRoutes.map((route, index) => {
        const { element, requireAuth, ...rest } = route;
        return <Route key={index} {...rest} element={requireAuth ? <AuthorizeRoute {...rest} element={element} /> : element} />;
      })}
    </Routes>
  );
}
export default App;
