import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import { useAppSelector } from "../../Hooks";
import { getLoadingState } from "../../store/main-process/selector";
import { Box, LinearProgress } from "@mui/material";

export default function Loader() {
  const loading = useAppSelector(getLoadingState);

  const [progress, setProgress] = React.useState(0);
  const [buffer, setBuffer] = React.useState(10);

  const progressRef = React.useRef(() => {});
  React.useEffect(() => {
    progressRef.current = () => {
      if (progress > 100) {
        setProgress(0);
        setBuffer(10);
      } else {
        const diff = Math.random() * 10;
        const diff2 = Math.random() * 10;
        setProgress(progress + diff);
        setBuffer(progress + diff + diff2);
      }
    };
  });

  React.useEffect(() => {
    const timer = setInterval(() => {
      progressRef.current();
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);
  return (
    <div>
      <Backdrop sx={{ color: "#fff", zIndex: 10000000000 }} open={loading}>
        {/* <CircularProgress color="inherit" /> */}
        <Box sx={{ width: "50%", textAlign: "center" }}>
          <h2>Идет загрузка</h2>
          <LinearProgress variant="buffer" color="success" value={progress} valueBuffer={buffer} />
        </Box>
        {/*  <p>fdsf</p> */}
      </Backdrop>
    </div>
  );
}
