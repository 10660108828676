import { State } from '../../types/state';
import { NameSpace } from '../../enums/enum';
import { IRoles, IShowAlert, IUserInfo } from '../../types/user-info/user-info';
import { UserRequest } from '../../types/user-info/user-request';
import { IUserMessage } from '../../types/user-info/user-message';

export const getUserInfo = (state: State): IUserInfo => state[NameSpace.User].userInfo;
export const getSelectLic = (state: State): string => state[NameSpace.User].selectLic;
export const getShwoAlert = (state: State): IShowAlert => state[NameSpace.User].showAlert;
export const getSearchUserByFio = (state: State): IUserInfo[] => state[NameSpace.User].searchUserByFio;
export const getAllRoles = (state: State): IRoles[] => state[NameSpace.User].roles;
export const getUserRequestAll = (state: State): UserRequest[] => state[NameSpace.User].userRequest;
export const getUserRequestMessage = (state: State): IUserMessage[] => state[NameSpace.User].userMessage;
