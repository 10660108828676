import * as React from 'react';
import Typography from '@mui/material/Typography';
import Title from '../title/title';

type data = {
  title:string, 
  value:number, 
  date:string
}

export default function CardValueData(data:data) {
    const [value, setValue] = React.useState(0.00);
    console.log(data.value);

    const step = data.value / 100; 
    React.useEffect(() => {
      const timer = setInterval(() => {
        setValue((value) => (value >= data.value ? data.value : value + step));
      }, 20);
      return () => {
        clearInterval(timer);
      };
    }, [data.value]);
  return (
    <React.Fragment>
      <Title>{data.title}</Title>
      <Typography component="p" variant="h4">
        {value.toFixed(2)}
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 1 }}>
      </Typography>
    </React.Fragment>
  );
}
