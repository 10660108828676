import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CameraIcon from "@mui/icons-material/Camera";
import IconButton from "@mui/material/IconButton";
import { Avatar, FormControl, Grid, InputLabel, List, ListItem, ListItemAvatar, ListItemText, MenuItem, Select, TextField, Typography, styled } from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAppDispatch, useAppSelector } from "../../../Hooks";
import ShowAlert from "../../allert/alert";
import { setShowAllert } from "../../../store/user-process/user-process";
import { AlertType, LocalStorageMethod } from "../../../enums/enum";
import { IShowAlert } from "../../../types/user-info/user-info";
import byteConverter from "../../../utils/byteConverter";
import { setLoading } from "../../../store/main-process/main-process";
import { RequestInaccuracyPu } from "../../../services/api-actions/user-action";
import { getUserInfo } from "../../../store/user-process/selector";
import LocalStorageProvider from "../../../utils/locaStorageProvider";
import { GetAllSubject } from "../../../services/api-actions/dictionaty-action";
import IPersonalUserRequestSubject from "../../../types/dictionary/PersonalUserRequestSubject";
import { CreatePersonalUserRequest, GetUserRequestAll } from "../../../services/api-actions/personal-user-request-action";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

type Data = {
  Open: boolean;
  Close: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function UserRequestCreate(data: Data) {
  const dispatch = useAppDispatch();
  const [text, setText] = React.useState("");
  const [file, setFile] = React.useState<File[]>([]);
  const [subject, setSubject] = React.useState<IPersonalUserRequestSubject[] | undefined>([]);
  const [selectSubjectId, setSelectSubjectId] = React.useState<number>({} as number);
  const userInfo = useAppSelector(getUserInfo);

  let defaultLic = LocalStorageProvider(userInfo.userId, null, LocalStorageMethod.getItem);

  const handleFileChange = (e: any) => {
    if (e.target.files[0] == undefined) return;
    if (e.target.files[0].name.includes(".exe")) {
      dispatch(setShowAllert({ open: true, message: "Нельзя загружать файл тип .exe", type: AlertType.error } as IShowAlert));
      return;
    }
    if (Number(byteConverter(e.target.files[0].size, 2, "MB").replace("MB", "")) > 15) {
      dispatch(setShowAllert({ open: true, message: "Загружаемый файл должен быть меньше 15 MB", type: AlertType.error } as IShowAlert));
      return;
    }
    if (e.target.files) {
      setFile([...file, e.target.files[0]]);
    }
  };
  const handleFileRemove = (e: any) => {
    setFile((f) => f.filter((item) => item.name != e));
  };

  React.useEffect(() => {
    dispatch(GetAllSubject()).then((result: any) => {
      setSubject(result?.payload as IPersonalUserRequestSubject[]);
    });
  }, [dispatch]);

  return (
    <React.Fragment>
      <Dialog
        open={data.Open}
        onClose={(e) => {
          data.Close(false);
          setFile([]);
          setText("");
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Создание нового обращения`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <FormControl fullWidth style={{ marginTop: "10px" }}>
              <InputLabel id="demo-simple-select-label">Тема</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectSubjectId}
                label="Age"
                onChange={(item: any) => {
                  setSelectSubjectId(item.target.value);
                }}
              >
                {subject?.map((item, index) => {
                  return <MenuItem value={item.id}>{item.subject}</MenuItem>;
                })}
              </Select>
            </FormControl>
            <TextField
              placeholder="напишите текст обращения"
              multiline
              fullWidth
              style={{ marginTop: "10px" }}
              onChange={(e) => {
                if (text.length > 200) {
                  e.target.value = text.substring(0, 200);
                  setText(e.target.value);
                } else {
                  setText(e.target.value);
                }
              }}
              rows={6}
              maxRows={4}
            />
            {text.length}/200
            <Button
              style={{ marginTop: "10px", float: "right" }}
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<AttachFileIcon />}
              onChange={(e) => handleFileChange(e)}
            >
              Добавить файл
              <VisuallyHiddenInput type="file" />
            </Button>
            {file.length > 0 && (
              <Grid item xs={12} md={6}>
                <Typography variant="h6" component="div">
                  Прикрепленные файлы
                </Typography>
                <List>
                  {file.map((item, index) => {
                    return (
                      <ListItem
                        secondaryAction={
                          <IconButton edge="end" aria-label="delete" onClick={() => handleFileRemove(item.name)}>
                            <DeleteIcon />
                          </IconButton>
                        }
                      >
                        <ListItemAvatar>
                          <Avatar>
                            <FolderIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={item.name} secondary={`Размер файл ${byteConverter(item.size, 2, "MB")}`} />
                      </ListItem>
                    );
                  })}
                </List>
              </Grid>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => {
              data.Close(false);
              setFile([]);
              setText("");
            }}
          >
            Отменить
          </Button>
          <Button
            onClick={(e) => {
              dispatch(setLoading(true));
              dispatch(CreatePersonalUserRequest({ SubjectId: selectSubjectId, Message: text, File: file, Lic: defaultLic.lic })).then(() => {
                dispatch(GetUserRequestAll());
              });

              dispatch(setLoading(false));
              setText("");
              setFile([]);
              data.Close(false);
            }}
            autoFocus
          >
            Отправить
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
