import ApiAuthorzationRoutes from './Components/api-authorization/ApiAuthorizationRoutes';
import Main from './Page/Main/Main';
import Page404 from "./Page/page-404/page-404";
import HistoryIndication from "./Page/history-indication/history-indication";
import Lic from "./Page/lic/lic";
import { AppRoute } from './enums/enum';
import { FetchData } from './FetchData';
import Counters from './Page/counters/counters';
import HistoryPayment from './Page/history-payment/history-payment';
import Test from './Page/Test/Test';
import Documents from './Page/documetns/documents';
import SendReadings from './Page/counters/send-readings';
import Admin from './Page/admin-panel/admin';
import UserRequests from './Page/user-request-page/user-requests';
import UserRequestChat from './Components/user-request-chat/user-request-chat';

const AppRoutes:any[] = [
  {
    path: AppRoute.Main,
    requireAuth: true,
    element: <Main />
  },
  {
    path: AppRoute.Lic,
    requireAuth: true,
    element: <Lic />
  },
  {
    path: AppRoute.Lic,
    requireAuth: true,
    element: <FetchData />
  },
  {
    path: AppRoute.HistroryIndication,
    requireAuth: true,
    element: <HistoryIndication />
  },
  {
    path: AppRoute.HistoryPayment,
    requireAuth: true,
    element: <HistoryPayment />
  },
  {
    path: AppRoute.Counter,
    requireAuth: true,
    element: <Counters />
  },
  {
    path: AppRoute.Documents,
    requireAuth: true,
    element: <Documents />
  },
  {
    path: AppRoute.SendReadings,
    requireAuth: true,
    element: <SendReadings />
  },
  {
    path: AppRoute.UserRequests,
    requireAuth: true,
    element: <UserRequests />
  },
  {
    path: `${AppRoute.UserRequestChat}/:id`,
    requireAuth: true,
    element: <UserRequestChat />
  },
  {
    path: AppRoute.Admin,
    requireAuth: true,
    element: <Admin />
  },
  {
    path: AppRoute.NotFound,
    requireAuth: false,
    element: <Page404 />
  },
  {
    path: AppRoute.Test,
    requireAuth: true,
    element: <Test />
  },
  ...ApiAuthorzationRoutes
];

export default AppRoutes;
