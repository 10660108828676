import {combineReducers} from '@reduxjs/toolkit';
import { NameSpace } from '../enums/enum';
import { dataMain } from './main-process/main-process';
import { dataUsers } from './user-process/user-process';
import { dataIntegration } from './integration-process/integration-process';

export const rootReducer = combineReducers({
  [NameSpace.User]: dataUsers.reducer,
  [NameSpace.MainData]: dataMain.reducer,
  [NameSpace.Integration]: dataIntegration.reducer
});
