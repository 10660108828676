import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useAppDispatch, useAppSelector } from "../../Hooks";
import { AddFullLicAtAccount, DeleteFullLicAtAccount, GetUserInfo } from "../../services/api-actions/user-action";
import { Button, Card, CardActions, CardContent, CardMedia, FormControl, Grid, NativeSelect, Tabs, TextField } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import "./style.css";
import useWindowDimensions from "../../Hooks/windowDimensions";
import { getUserInfo } from "../../store/user-process/selector";
import LocalStorageProvider from "../../utils/locaStorageProvider";
import { AlertType, LocalStorageMethod } from "../../enums/enum";
import { useState } from "react";
import { AddToHomeScreen } from "react-pwa-add-to-homescreen";
import authService from "../../Components/api-authorization/AuthorizeService";
import AddLic from "../../Components/dialog/lic/AddLic";
import { getPersonalInfo, getUserLicAddresses } from "../../store/integration-process/selector";
import valueIsNullOrUndefined from "../../utils/valueOrDefault";
import LicDialog from "../../Components/dialog/lic/LicDialog";
import QueryElectricalReceipt from "../../Components/dialog/lic/QueryElectricalReceipt";
import DeleteLic from "../../Components/dialog/lic/delete-lic";

export default function Lic() {
  const [openDialogAddLic, setOpenDialogAddLic] = useState(false);
  const [selectLicDelete, setSelectLicDelete] = useState("");
  const [openDialogDeleteLic, setOpenDialogDeleteLic] = useState(false);
  const [openElectricalReceiptDialog, setOpenElectricalReceiptDialog] = useState(false);
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    dispatch(GetUserInfo());
  }, [dispatch]);
  const personalInfo = useAppSelector(getPersonalInfo);
  const mainPersonalInfo = personalInfo.personsData?.find((x) => x.isMain === true);
  const userInfo = useAppSelector(getUserInfo);
  const userLic = useAppSelector(getUserLicAddresses);
  const { height, width } = useWindowDimensions();
  let MinWidth = width < 800 ? "300px" : "230px";
  const colWidth = { xs: 12, sm: 12, md: 6, lg: 6 } as const;
  if (userInfo.lics?.length == 1) {
    LocalStorageProvider(userInfo.userId, { lic: userInfo.lics[0].lic, address: "" }, LocalStorageMethod.setItem);
  }
  let defaultLic = LocalStorageProvider(userInfo.userId, null, LocalStorageMethod.getItem);
  if (userInfo.lics?.length >= 1 && defaultLic == "") {
    LocalStorageProvider(userInfo.userId, { lic: userInfo.lics[0].lic, address: "" }, LocalStorageMethod.setItem);
  }
  if (defaultLic != "" && userInfo?.lics.find((x) => x.lic == defaultLic.lic) == undefined) {
    defaultLic = "";
  }

  return (
    <>
      <div className="padingMedia">
        <AddToHomeScreen />
        {defaultLic.lic !== undefined && defaultLic != "" && (
          <Box sx={{ flexGrow: 2, pt: width < 595 ? 3 : 10, pl: width < 595 ? 0 : 10 }}>
            <Typography gutterBottom variant="h4" component="div" align="left">
              Мои лицевые счета
            </Typography>

            <Grid container spacing={2} marginTop={1}>
              <Grid item {...{ xs: 12, sm: 12, md: 6, lg: 6 }} paddingBottom={1} minHeight={160}>
                <Typography gutterBottom variant="h6" component="div" color="gray" align="left">
                  Выбранный счет
                </Typography>
                <Card>
                  <CardMedia sx={{ height: 100 }}>
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        {defaultLic?.lic} <span></span>
                        {userInfo.lics?.length >= 1 && (
                          <Button
                            variant="contained"
                            size="small"
                            style={{ float: "right" }}
                            color="error"
                            endIcon={<DeleteIcon />}
                            onClick={() => {
                              setSelectLicDelete(defaultLic?.lic);
                              setOpenDialogDeleteLic(true);
                              /*    dispatch(DeleteFullLicAtAccount({userId:userInfo.userId, fullLic:defaultLic?.lic}))
                          LocalStorageProvider(userInfo.userId,defaultLic?.lic,LocalStorageMethod.removeItem);
                          window.location.reload(); */
                            }}
                          >
                            Удалить л/с
                          </Button>
                        )}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {personalInfo?.address?.fullAddress}
                      </Typography>
                    </CardContent>
                  </CardMedia>
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="div" color="gray" align="left">
                      Задолженность на текущую дату
                      <Typography gutterBottom variant="h6" component="div" color="gray" align="left">
                        {personalInfo.currentDebt} ₽
                      </Typography>
                    </Typography>

                    {mainPersonalInfo?.hasElectronicReceipts == true && (
                      <Typography variant="body1">Электронная квитанция подключена на email: {mainPersonalInfo?.email}</Typography>
                    )}
                  </CardContent>
                  <CardActions>
                    <Button size="small" onClick={() => setOpenElectricalReceiptDialog(true)}>
                      Управление электронной квитанцией
                    </Button>
                  </CardActions>
                  <DeleteLic
                    Open={openDialogDeleteLic}
                    Close={setOpenDialogDeleteLic}
                    Title={`Вы уверены что хотите удалить лицевой счет ${selectLicDelete}`}
                    Lic={defaultLic?.lic}
                    UserId={userInfo.userId}
                  />
                </Card>
              </Grid>
              <LicDialog
                Open={openElectricalReceiptDialog}
                Close={setOpenElectricalReceiptDialog}
                Title="Управление электронной квитанцией"
                Component={<QueryElectricalReceipt />}
              />
              <Grid item {...{ xs: 12, sm: 12, md: 6, lg: 6 }} paddingBottom={1} minHeight={160}>
                <Typography gutterBottom variant="h6" component="div" color="gray" align="left">
                  Информация о собственнике
                </Typography>
                <Card>
                  <CardMedia sx={{ height: 230 }}>
                    <CardContent>
                      <Typography variant="body2" color="text.secondary">
                        ФИО: {valueIsNullOrUndefined(mainPersonalInfo?.lastName, "")} {valueIsNullOrUndefined(mainPersonalInfo?.firstName, "")}{" "}
                        {valueIsNullOrUndefined(mainPersonalInfo?.middleName, "")}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Адрес: {personalInfo?.address?.fullAddress}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Площадь помещения: {personalInfo?.square}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Кол-во зарегистрированных: {personalInfo?.numberOfPersons}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Телефон1: {mainPersonalInfo?.firstPhoneNumber}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Телефон2: {mainPersonalInfo?.secondPhoneNumber}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Эл. Почта: {mainPersonalInfo?.email}
                      </Typography>
                    </CardContent>
                  </CardMedia>
                  <CardActions></CardActions>
                </Card>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              {userLic
                .filter((x) => x.lic != defaultLic?.lic && userInfo.lics.filter((z) => z.lic == x.lic).length > 0)
                .map((item, index) => {
                  return (
                    <Grid item {...colWidth} minHeight={160}>
                      <Card>
                        <CardMedia sx={{ height: 100 }}>
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                              {item?.lic}
                              {userInfo.lics?.length > 1 && (
                                <Button
                                  variant="contained"
                                  size="small"
                                  style={{ float: "right" }}
                                  color="error"
                                  endIcon={<DeleteIcon />}
                                  onClick={() => {
                                    setSelectLicDelete(item?.lic);
                                    setOpenDialogDeleteLic(true);
                                    /*  dispatch(DeleteFullLicAtAccount({userId:userInfo.userId, fullLic:item.lic}))
                              LocalStorageProvider(userInfo.userId,item.lic,LocalStorageMethod.removeItem);
                              window.location.reload(); */
                                  }}
                                >
                                  Удалить л/с
                                </Button>
                              )}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              {item?.fullAddress}
                            </Typography>
                          </CardContent>
                        </CardMedia>
                        <CardActions>
                          <Button
                            size="small"
                            onClick={(e: any) => {
                              LocalStorageProvider(userInfo.userId, { lic: item?.lic, address: "" }, LocalStorageMethod.setItem);
                              window.location.reload();
                            }}
                          >
                            Установить по умолчанию
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>
                  );
                })}
              <DeleteLic
                Open={openDialogDeleteLic}
                Close={setOpenDialogDeleteLic}
                Title={`Вы уверены что хотите удалить лицевой счет ${selectLicDelete}`}
                Lic={selectLicDelete}
                UserId={userInfo.userId}
              />
              <Grid item {...{ xs: 12, sm: 12, md: 12, lg: 12 }} paddingBottom={1} minHeight={160}>
                <Card style={{ backgroundColor: "#f0f3f4" }}>
                  <CardMedia sx={{ height: 100 }}>
                    <CardContent style={{ textAlign: "center" }}>
                      <Typography style={{ marginLeft: "auto" }} gutterBottom variant="h5" component="div">
                        <Button variant="contained" size="small" color="success" endIcon={<PersonAddIcon />} onClick={() => setOpenDialogAddLic(true)}>
                          Добавить лицевой счёт
                        </Button>
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {"Вы можете добавить под управление другие лицевые счета"}
                      </Typography>
                    </CardContent>
                  </CardMedia>
                </Card>
              </Grid>
              <AddLic Open={openDialogAddLic} Close={setOpenDialogAddLic} Title="Добавить лицевой счет" />
            </Grid>
          </Box>
        )}
        {defaultLic.lic == undefined && (
          <>
            <Box sx={{ flexGrow: 2, p: width < 595 ? 2 : 10 }}>
              <Grid item {...{ xs: 12, sm: 12, md: 12, lg: 12 }} paddingBottom={1} minHeight={160}>
                <Card style={{ backgroundColor: "#f0f3f4" }}>
                  <CardMedia sx={{ height: 100 }}>
                    <CardContent style={{ textAlign: "center" }}>
                      <Typography style={{ marginLeft: "auto" }} gutterBottom variant="h5" component="div">
                        <Button variant="contained" size="small" color="success" endIcon={<PersonAddIcon />} onClick={() => setOpenDialogAddLic(true)}>
                          Добавить лицевой счёт
                        </Button>
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        <b>{"У вас пока нет лицевых счетов."}</b>
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {"После добавления лицевого счета вы сможете воспользовать личным кабинетом в полном обьеме."}
                      </Typography>
                    </CardContent>
                  </CardMedia>
                </Card>
              </Grid>
              <AddLic Open={openDialogAddLic} Close={setOpenDialogAddLic} Title="Добавить лицевой счет" />
            </Box>
          </>
        )}
      </div>
    </>
  );
}
