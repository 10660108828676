import { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import authService from "../../Components/api-authorization/AuthorizeService";
import { Api } from "../../store";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppDispatch, State } from "../../types/state";
import { AlertType, APIReport, APIRouteIntegration, AppRoute } from "../../enums/enum";
import valueIsNullOrUndefined from "../../utils/valueOrDefault";
import { setShowAllert } from "../../store/user-process/user-process";
import { setLoading } from "../../store/main-process/main-process";
import { FormatString } from "../../utils/stringHelper";
import { IShowAlert } from "../../types/user-info/user-info";


interface AdaptAxiosRequestConfig extends AxiosRequestConfig {
  headers: AxiosRequestHeaders
}

async function AddBearerToken(Services:Api){
  const user = await authService.getUser();
  const token = await authService.getAccessToken();
  var seconds = Math.floor(Date.now() / 1000);
  if(seconds > user.auth_time + 21600){
    window.location.href = AppRoute.RefreshToken;
  }
  Services.Integrations.interceptors.request.use(
    (config: AdaptAxiosRequestConfig) => {
      config.headers['Authorization'] = `Bearer ${token}`;
      return config;
    }
  );
}

export const UserPersonalAccount = createAsyncThunk<void, {startDate:string,endDate:string}, {
  dispatch: AppDispatch,
  state: State,
  extra: Api
}>(
  'data/UserPersonalAccount',
  async ({startDate, endDate}, {dispatch, extra: Services }) => {
    try{
      console.log('Test');
      if(valueIsNullOrUndefined(startDate,'') == ''){
        dispatch(setShowAllert({open:true,message:`Не указана начальная дата.`,type:AlertType.error}as IShowAlert));
        return;
      }
      if(valueIsNullOrUndefined(endDate,'') == ''){
        dispatch(setShowAllert({open:true,message:`Не указана начальная дата.`,type:AlertType.error}as IShowAlert));
        return;
      }
      dispatch(setLoading(true));

      const res =  await Services.Identity.get(FormatString(`${APIReport.GetUserPersonalAccount}`,startDate,endDate), { responseType: "blob" });
        
        
        let url = window.URL
          .createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Пользователи ЛК ${startDate}-${endDate}.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);


      dispatch(setLoading(false));
    }catch(e:any){
      console.log(e);
      dispatch(setLoading(false));
      dispatch(setShowAllert({open:true,message:`Произошла ошибка при обработке запроса.`,type:AlertType.error}as IShowAlert));
      throw new Error(e);
    }
  },
);
export const PersonalUserRequest = createAsyncThunk<void, {startDate:string,endDate:string}, {
  dispatch: AppDispatch,
  state: State,
  extra: Api
}>(
  'data/PersonalUserRequest',
  async ({startDate, endDate}, {dispatch, extra: Services }) => {
    try{
      console.log('Test');
      if(valueIsNullOrUndefined(startDate,'') == ''){
        dispatch(setShowAllert({open:true,message:`Не указана начальная дата.`,type:AlertType.error}as IShowAlert));
        return;
      }
      if(valueIsNullOrUndefined(endDate,'') == ''){
        dispatch(setShowAllert({open:true,message:`Не указана начальная дата.`,type:AlertType.error}as IShowAlert));
        return;
      }
      dispatch(setLoading(true));

      const res =  await Services.Identity.get(FormatString(`${APIReport.GetPersonalUserRequest}`,startDate,endDate), { responseType: "blob" });
        
        
        let url = window.URL
          .createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Обращения ${startDate}-${endDate}.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);


      dispatch(setLoading(false));
    }catch(e:any){
      console.log(e);
      dispatch(setLoading(false));
      dispatch(setShowAllert({open:true,message:`Произошла ошибка при обработке запроса.`,type:AlertType.error}as IShowAlert));
      throw new Error(e);
    }
  },
);

export const WorkInOperator = createAsyncThunk<void, {startDate:string,endDate:string}, {
  dispatch: AppDispatch,
  state: State,
  extra: Api
}>(
  'data/WorkInOperator',
  async ({startDate, endDate}, {dispatch, extra: Services }) => {
    try{
      console.log('Test');
      if(valueIsNullOrUndefined(startDate,'') == ''){
        dispatch(setShowAllert({open:true,message:`Не указана начальная дата.`,type:AlertType.error}as IShowAlert));
        return;
      }
      if(valueIsNullOrUndefined(endDate,'') == ''){
        dispatch(setShowAllert({open:true,message:`Не указана начальная дата.`,type:AlertType.error}as IShowAlert));
        return;
      }
      dispatch(setLoading(true));

      const res =  await Services.Identity.get(FormatString(`${APIReport.GetWorkInOperator}`,startDate,endDate), { responseType: "blob" });
        
        
        let url = window.URL
          .createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `В работе у оператор ${startDate}-${endDate}.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);


      dispatch(setLoading(false));
    }catch(e:any){
      console.log(e);
      dispatch(setLoading(false));
      dispatch(setShowAllert({open:true,message:`Произошла ошибка при обработке запроса.`,type:AlertType.error}as IShowAlert));
      throw new Error(e);
    }
  },
);
export const UserInfoReport = createAsyncThunk<void,undefined, {
  dispatch: AppDispatch,
  state: State,
  extra: Api
}>(
  'data/UserInfoReport',
  async ( undefined, {dispatch, extra: Services }) => {
    try{
      dispatch(setLoading(true));

      const res =  await Services.Identity.get(FormatString(`${APIReport.GetUserInfoReport}`), { responseType: "blob" });
        
        
        let url = window.URL
          .createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Пользователи ЛК.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);


      dispatch(setLoading(false));
    }catch(e:any){
      console.log(e);
      dispatch(setLoading(false));
      dispatch(setShowAllert({open:true,message:`Произошла ошибка при обработке запроса.`,type:AlertType.error}as IShowAlert));
      throw new Error(e);
    }
  },
);