import { IUserInfo } from "../types/user-info/user-info";

export function userIsRole(user:IUserInfo | undefined,role:string ){
  if(user == undefined){
    return false;
  }
  if(user.roles?.includes(role)){
    return true;
  }else{
    return false;
  }
}