import { State } from '../../types/state';
import { NameSpace } from '../../enums/enum';
import { IPersonalInfo } from '../../types/type-integration/persona-info';
import { IReadingHistory } from '../../types/type-integration/reading-history';
import IUserLicAddresses from '../../types/type-integration/userLicAddresses';
import IPayments from '../../types/type-integration/payments-history';


export const getPersonalInfo = (state: State): IPersonalInfo => state[NameSpace.Integration].personalInfo;
export const getReadingHistory = (state: State): IReadingHistory[] => state[NameSpace.Integration].readingHistory;
export const getPaymentHistory = (state: State): IPayments[] => state[NameSpace.Integration].payments;
export const getUserLicAddresses = (state: State): IUserLicAddresses[] => state[NameSpace.Integration].userLicAddresses;
export const getPeriodLockStatus = (state: State): boolean => state[NameSpace.Integration].periodLockStatus;

