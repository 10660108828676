import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import HistoryIndicationTable from "../../Components/table/history-indication-table/history-indication-table";
import HistoryIndicationGraphics from "../../Components/graphics/history-indication-graphics/history-indication-graphics";
import useWindowDimensions from "../../Hooks/windowDimensions";
import dayjs from "dayjs";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import LocalStorageProvider from "../../utils/locaStorageProvider";
import { AppRoute, LocalStorageMethod } from "../../enums/enum";
import { useAppDispatch, useAppSelector } from "../../Hooks";
import { getUserInfo } from "../../store/user-process/selector";
import { getPersonalInfo, getReadingHistory } from "../../store/integration-process/selector";
import CardValueData from "../../Components/card-value-date/card-value-date";
import { TextField } from "@mui/material";
import { GetReadingHistory } from "../../services/api-actions/integration-action";
import LicDialog from "../../Components/dialog/lic/LicDialog";
import AddIndinications from "../../Components/dialog/lic/AddIndinications";

const defaultTheme = createTheme();

export default function HistoryIndication() {
  const dispatch = useAppDispatch();
  const userInfo = useAppSelector(getUserInfo);
  const readingHistory = useAppSelector(getReadingHistory);
  const personalInfo = useAppSelector(getPersonalInfo);
  const [selectSearchValue, setSelectSearchValue] = React.useState({
    from: "",
    to: "",
    typePu: "",
  });
  const readings = personalInfo?.counters?.find((x) => x.typePu == selectSearchValue.typePu);
  const [openIndinicationDialog, setOpenIndinicationDialog] = React.useState(false);

  const { height, width } = useWindowDimensions();
  let MinWidth = width < 800 ? "10px" : "230px";
  return (
    <ThemeProvider theme={defaultTheme}>
      <Box>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) => (theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[900]),
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }} style={width >= 600 ? { paddingLeft: "70px" } : { paddingLeft: "12px" }}>
            <Grid container spacing={2}>
              {/* Date Time */}
              <Grid item xs={6} md={3} lg={3}>
                <TextField
                  style={{ marginTop: "0px", minWidth: MinWidth }}
                  id="IndicationDate"
                  label="Дата с"
                  type="month"
                  InputLabelProps={{ shrink: true }}
                  autoComplete="current-password"
                  onInput={(e: any) => {
                    setSelectSearchValue({ to: selectSearchValue.to, from: toJSONLocal(new Date(e.target.value + "-01")), typePu: selectSearchValue.typePu });
                  }}
                  defaultValue={toJSONLocal(new Date())}
                />
              </Grid>
              <Grid item xs={6} md={3} lg={3}>
                <TextField
                  style={{ marginTop: "0px", minWidth: MinWidth }}
                  id="IndicationDate"
                  label="Дата по"
                  type="month"
                  InputLabelProps={{ shrink: true }}
                  autoComplete="current-password"
                  onInput={(e: any) => {
                    setSelectSearchValue({ to: toJSONLocal(new Date(e.target.value + "-01")), from: selectSearchValue.from, typePu: selectSearchValue.typePu });
                  }}
                  defaultValue={toJSONLocal(new Date())}
                />
              </Grid>
              <Grid item xs={6} md={3} lg={3}>
                <FormControl fullWidth>
                  <InputLabel id="demo-controlled-open-select-label">Тип ПУ</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectSearchValue.typePu}
                    label="Тип ПУ"
                    onChange={(e: any) => {
                      setSelectSearchValue({ from: selectSearchValue.from, to: selectSearchValue.to, typePu: e.target.value });
                    }}
                  >
                    {personalInfo.counters?.map((item) => (
                      <MenuItem value={item.typePu}>{item.typePu}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} md={3} lg={3}>
                <Button
                  variant="contained"
                  disableElevation
                  fullWidth
                  style={{ minHeight: "55px" }}
                  onClick={(e: any) => {
                    dispatch(
                      GetReadingHistory({
                        fullLic: LocalStorageProvider(userInfo.userId, null, LocalStorageMethod.getItem).lic,
                        typePu: selectSearchValue.typePu,
                        from: dayjs(selectSearchValue.from).format("YYYY-MM-DD"),
                        to: selectSearchValue.to == "" ? "" : dayjs(selectSearchValue.to)?.format("YYYY-MM-DD"),
                      })
                    );
                  }}
                >
                  Показать
                </Button>
              </Grid>
              {/* Chart */}
              {readingHistory?.length > 0 && (
                <>
                  <Grid item xs={12} md={4} lg={3}>
                    <Paper
                      sx={{
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                        height: 240,
                      }}
                    >
                      <CardValueData title="Последние показания" date="2024-01-31" value={Number(Math.max(...readingHistory.map((o) => o.readings)))} />
                      <Button variant="contained" onClick={() => (window.location.href = AppRoute.SendReadings)}>
                        Передать показания
                      </Button>
                    </Paper>
                  </Grid>

                  <Grid item xs={12} md={8} lg={9}>
                    <Paper
                      sx={{
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                        height: 240,
                      }}
                    >
                      <HistoryIndicationGraphics data={readingHistory} />
                    </Paper>
                  </Grid>

                  <Grid item xs={12} style={{ marginBottom: "40px" }}>
                    <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                      <HistoryIndicationTable data={readingHistory} />
                    </Paper>
                  </Grid>
                </>
              )}
            </Grid>
            <LicDialog Open={openIndinicationDialog} Close={setOpenIndinicationDialog} Title="Внести показания" Component={<AddIndinications />} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
function toJSONLocal(date: Date) {
  var local = new Date(date);
  local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  return local.toJSON().slice(0, 10);
}
