import { Button, Card, CardActions, CardContent, CardMedia, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import React, { useState } from "react";
import ApiAuthorzationRoutes from "../../api-authorization/ApiAuthorizationRoutes";
import { useAppDispatch, useAppSelector } from "../../../Hooks";
import { AddFullLicAtAccount, AddQueueReceipt, ChangeElectronicReceipts, GetUserInfo } from "../../../services/api-actions/user-action";
import { getUserInfo } from "../../../store/user-process/selector";
import useWindowDimensions from "../../../Hooks/windowDimensions";
import AuthorizeRoute from "../../api-authorization/AuthorizeRoute";
import Main from "../../../Page/Main/Main";
import ShowAlert from "../../allert/alert";
import { Link } from "react-router-dom";
import { AppRoute, LocalStorageMethod } from "../../../enums/enum";
import LocalStorageProvider from "../../../utils/locaStorageProvider";
import { getPersonalInfo } from "../../../store/integration-process/selector";
import { setLoading } from "../../../store/main-process/main-process";

export enum Solution {
  Refuse = "Refuse",
  Agree = "Agree",
  ChangeEmail = "ChangeEmail",
}

export default function QueryElectricalReceipt() {
  const { height, width } = useWindowDimensions();
  let MinWidth = width < 800 ? "300px" : "230px";
  const [ectronicReceipts, setElectronicReceipts] = useState({
    action: "",
    email: "",
  });
  const userInfo = useAppSelector(getUserInfo);
  const dispatch = useAppDispatch();
  const personalInfo = useAppSelector(getPersonalInfo);
  const mainPersonalInfo = personalInfo.personsData?.find((x) => x.isMain === true);

  let defaultLic = LocalStorageProvider(userInfo.userId, null, LocalStorageMethod.getItem);
  if (userInfo.lics?.length >= 1 && defaultLic == "") {
    LocalStorageProvider(userInfo.userId, { lic: userInfo.lics[0].lic, address: "" }, LocalStorageMethod.setItem);
  }

  console.log(ectronicReceipts.action);
  return (
    <>
      <Card>
        <CardMedia style={{ padding: "10px" }}>
          <p>Здесь вы можете отказаться или подключить отправку электронных квитанций</p>
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">Выберите действие</FormLabel>
            <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
              <>
                {mainPersonalInfo?.hasElectronicReceipts == true && (
                  <FormControlLabel
                    value="female"
                    control={<Radio />}
                    onClick={() => setElectronicReceipts({ action: Solution.Refuse, email: ectronicReceipts.email })}
                    label="Отключить"
                  />
                )}
                {mainPersonalInfo?.hasElectronicReceipts == false && (
                  <FormControlLabel
                    value="male"
                    control={<Radio />}
                    onClick={() => setElectronicReceipts({ action: Solution.Agree, email: ectronicReceipts.email })}
                    label="Подключить"
                  />
                )}
                {mainPersonalInfo?.hasElectronicReceipts == true && (
                  <FormControlLabel
                    value="other"
                    control={<Radio />}
                    onClick={() => setElectronicReceipts({ action: Solution.ChangeEmail, email: ectronicReceipts.email })}
                    label="Изменить адрес электронной почты"
                  />
                )}
                {(ectronicReceipts.action == Solution.ChangeEmail || ectronicReceipts.action == Solution.Agree) && (
                  <TextField
                    onChange={(e: any) => setElectronicReceipts({ action: ectronicReceipts.action, email: e.target.value })}
                    id="standard-basic"
                    label="Укажите Email"
                    variant="standard"
                  />
                )}
              </>
            </RadioGroup>
          </FormControl>
        </CardMedia>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            <Button
              variant="contained"
              color="success"
              endIcon={<SendIcon />}
              onClick={() => {
                dispatch(setLoading(true));
                dispatch(ChangeElectronicReceipts({ action: ectronicReceipts.action, email: ectronicReceipts.email, lic: defaultLic.lic }));
                dispatch(setLoading(false));
              }}
            >
              Отправить запрос
            </Button>
          </Typography>
        </CardContent>
        <CardActions></CardActions>
      </Card>
    </>
  );
}
function toJSONLocal(date: Date) {
  var local = new Date(date);
  local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  return local.toJSON().slice(0, 10);
}
