import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Container, Grid, Tabs, ThemeProvider, Toolbar, createTheme } from '@mui/material';
import useWindowDimensions from '../../Hooks/windowDimensions';
import AdduserLic from './tab-panel/add-user-lic';
import Reports from './tab-panel/reports';
import Roles from './tab-panel/roles';
import { userIsRole } from '../../utils/user-is-role';
import { UserRole } from '../../enums/enum';
import { useAppSelector } from '../../Hooks';
import { getUserInfo } from '../../store/user-process/selector';
import OperatorProcessingRequests from './tab-panel/operator-processing-requests';

const defaultTheme = createTheme();

export default function Admmin(){

  const [value, setValue] = React.useState('1');
  const userInfo = useAppSelector(getUserInfo);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const { height, width } = useWindowDimensions();

  return(
  <>
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }} >
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow:'scroll'
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4}} style={ width >= 600 ? { paddingLeft:'70px'} : { paddingLeft:'12px'} }>
          <Grid container spacing={0}>
          <Box sx={{ width: '100%', typography: 'body1' }}>
            
            <TabContext value={value} >
              <Box sx={{ borderBottom: 1, borderColor: 'divider'}} >
              <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              
              aria-label="full width tabs example"
            >
              {(userIsRole(userInfo,UserRole.Operator) ||userIsRole(userInfo,UserRole.Admin)) &&
                    <Tab label="Привязка лицевых счетов" value="1" />
              }
              {(userIsRole(userInfo,UserRole.Admin) || userIsRole(userInfo,UserRole.OperatorAdmin)) &&
                <Tab label="Отчеты" value="2" />
              }
              {(userIsRole(userInfo,UserRole.Admin)) &&
                <Tab label="Управление ролями" value="3" />
              }
              {(userIsRole(userInfo,UserRole.Operator) || userIsRole(userInfo,UserRole.Admin)) &&
                <Tab label="Обращения" value="4" />
              }
            </Tabs>
              </Box>
              <TabPanel value="1">
                <AdduserLic />
              </TabPanel>
              <TabPanel value="2">
                <Reports />
              </TabPanel>
              <TabPanel value="3">
                <Roles />
              </TabPanel>
              <TabPanel value="4">
                <OperatorProcessingRequests />
              </TabPanel>
            </TabContext>
          </Box>
          </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  </>
  );
}
