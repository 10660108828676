import { FormControl, NativeSelect } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import LocalStorageProvider from "../../../utils/locaStorageProvider";
import { useAppSelector } from "../../../Hooks";
import { getUserInfo } from "../../../store/user-process/selector";
import { AppRoute, LocalStorageMethod } from "../../../enums/enum";
import { getPersonalInfo, getUserLicAddresses } from "../../../store/integration-process/selector";

export default function ChangeLick(){
  const userInfo = useAppSelector(getUserInfo);
  const userLic = useAppSelector(getUserLicAddresses);
  const colWidth = { xs: 12, sm: 12, md: 6, lg: 6 } as const;
  let defaultLic = LocalStorageProvider(userInfo.userId,null,LocalStorageMethod.getItem);
  if(userInfo.lics?.length >= 1 && defaultLic == ""){
    LocalStorageProvider(userInfo.userId,{lic: userInfo.lics[0].lic, address:''},LocalStorageMethod.setItem);
  }
  const address = useAppSelector(getPersonalInfo)?.address;
  return(
    <>
      <Link style={{textDecoration:'none', backgroundColor: 'none', color: 'black', fontSize: '20px', paddingRight:'10px' }} to={AppRoute.Lic} >
        Установить лицевой счет по умолчанию
      </Link>
      <FormControl fullWidth className='NativeSelect-White' >
        <NativeSelect 
          onChange={(e:React.ChangeEvent<HTMLSelectElement>)=>{
            LocalStorageProvider(userInfo.userId,{lic: e.target.value, address:''},LocalStorageMethod.setItem);
            window.location.reload();
          }}
          defaultValue={''}
        >
          <option key={defaultLic.lic} value={defaultLic.lic}>{`${defaultLic.lic}  ${address.fullAddress}`}</option>
          {userLic.length >0 &&userLic.map(function(item, i){
              if(item.lic != defaultLic.lic && userInfo.lics.filter(x=>x.lic == item.lic).length > 0){
                return  <option key={item.lic} value={item.lic}>{`${item.lic} ${item.fullAddress}`}</option>
              }
          })}
        </NativeSelect>
      </FormControl>
    </>

  );
}