import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../Hooks";
import { AddLicForUserAdmin, SearchUserFio } from "../../../services/api-actions/user-action";
import { getSearchUserByFio } from "../../../store/user-process/selector";
import { useEffect, useId, useState } from "react";

export default function AdduserLic(){

  const colWidth = { xs: 12, sm: 4, md: 4, lg: 4 } as const;

  const dispatch = useAppDispatch();
  const [searchValue,setSearchValue] = useState("");
  const [selectUserInfo,setSelectUserInfo] = useState({
    userId:'',
    lic:'',
    fio:''
  });
  console.log(selectUserInfo);
  const searchUser = useAppSelector(getSearchUserByFio)?.map((item)=> { 
    return { label: `${item.fio} ${item.email}` , id: item.userId };
   });
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch(SearchUserFio({fio:searchValue}));
    }, 2000);
    return () => clearTimeout(timeoutId);
  }, [searchValue]);

  return(
  <>
    <Grid container spacing={2}>
      <Grid item {...colWidth} >
        <Autocomplete
            disablePortal
            noOptionsText={'Ничего не найдено'}
            id="combo-box-demo"
            options={searchUser ?? []}
            onChange={(event, newValue) => {
              setSelectUserInfo({...selectUserInfo, userId:newValue?.id ?? '', fio:newValue?.label ?? ''})
            }}
            onInputChange={(event: object, value: any)=>{
              setSearchValue(value);
            }}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Пользователь" />}
          />
      </Grid>
      <Grid item {...colWidth} >
        <TextField
          id="outlined-number"
          label=""
          type="text"
          InputLabelProps={{
            shrink: true,
          }}
          helperText={`Лицевой счет`}
          onChange={(e:any)=>{
            setSelectUserInfo({...selectUserInfo, lic:e.target.value})
          }}
        />
      </Grid>
      <Grid item {...colWidth} >
        <Button variant="contained" color="success" onClick={()=>{
          dispatch(AddLicForUserAdmin({UserId:selectUserInfo.userId,Lic:selectUserInfo.lic}));
        }}
        >
          Добавить
        </Button>
      </Grid>
    </Grid>
  </>
  );
}
