import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppDispatch, State } from "../../types/state";
import { Api } from "../../store";
import { AlertType, APIRequestUser, AppRoute, LocalStorageMethod } from "../../enums/enum";
import { setShowAllert } from "../../store/user-process/user-process";
import { IShowAlert } from "../../types/user-info/user-info";
import authService from "../../Components/api-authorization/AuthorizeService";
import { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { UserRequest } from "../../types/user-info/user-request";
import { setLoading } from "../../store/main-process/main-process";
import { FormatString } from "../../utils/stringHelper";
import { IUserMessage } from "../../types/user-info/user-message";
import valueIsNullOrUndefined from "../../utils/valueOrDefault";
import Lic from "../../Page/lic/lic";

interface AdaptAxiosRequestConfig extends AxiosRequestConfig {
  headers: AxiosRequestHeaders
}


async function AddBearerToken(Services:Api){
  const user = await authService.getUser();
  const token = await authService.getAccessToken();
  var seconds = Math.floor(Date.now() / 1000);
  if(seconds > user.auth_time + 21600){
    window.location.href = AppRoute.RefreshToken;
  }
  Services.Integrations.interceptors.request.use(
    function (config: AdaptAxiosRequestConfig) {
    config.headers['Authorization'] = `Bearer ${token}`;
    return config;
  }
  );
}

export const CreatePersonalUserRequest = createAsyncThunk<void, {SubjectId:number,Message:string,File:File[],Lic:string}, {
  dispatch: AppDispatch,
  state: State,
  extra: Api
}>(
  'data/CreatePersonalUserRequest',
  async ({SubjectId,Message,File,Lic}, {dispatch, extra: Services }) => {
    try{
      let data = new FormData();
      data.append("SubjectId", SubjectId.toString());
      data.append("Message", Message);
      data.append("Lic", Lic);
      File.map(item=>data.append("Files", item));
      await Services.Identity.post(APIRequestUser.CreatePersonalUserRequest,data);
      dispatch(setShowAllert({open:true,message:`Ваше обращение успешно создано!`,type:AlertType.success}as IShowAlert));
    }catch(e:any){
      console.log(e);
      dispatch(setShowAllert({open:true,message:`${e.response.data}`,type:AlertType.error}as IShowAlert));
      throw new Error(e);
    }
    
  },
);



export const GetUserRequestAll = createAsyncThunk<UserRequest[], undefined, {
  dispatch: AppDispatch,
  state: State,
  extra: Api
}>(
  'data/GetUserRequestAll',
  async (_arg, {dispatch,extra: Services }) => {
    try{
      dispatch(setLoading(true));
      await AddBearerToken(Services);
      const {data} =  await Services.Identity.get(APIRequestUser.GetUserRequestAll);

      dispatch(setLoading(false));
      return data;
    }catch(e:any){
      dispatch(setLoading(false));
      dispatch(setShowAllert({open:true,message:`${e.response.data}`,type:AlertType.error}as IShowAlert));
    }
  },
);

export const GetUserRequestAllOperrator = createAsyncThunk<UserRequest[], undefined, {
  dispatch: AppDispatch,
  state: State,
  extra: Api
}>(
  'data/GetUserRequestAllOperrator',
  async (_arg, {dispatch,extra: Services }) => {
    try{
      dispatch(setLoading(true));
      await AddBearerToken(Services);
      const {data} =  await Services.Identity.get(APIRequestUser.GetUserRequestAllOperrator);

      dispatch(setLoading(false));
      return data;
    }catch(e:any){
      dispatch(setLoading(false));
      dispatch(setShowAllert({open:true,message:`${e.response.data}`,type:AlertType.error}as IShowAlert));
    }
  },
);

export const GetUserRequestMessage = createAsyncThunk<IUserMessage[], {chatId:string | undefined}, {
  dispatch: AppDispatch,
  state: State,
  extra: Api
}>(
  'data/GetUserRequestMessage',
  async ({chatId}, {dispatch,extra: Services }) => {
    try{
      dispatch(setLoading(true));
      await AddBearerToken(Services);
      if(chatId == undefined)
        throw new Error('Неизвестный индификатор чата');
      const {data} =  await Services.Identity.get(FormatString(APIRequestUser.GetAllUserRequestMessage,chatId));
      
      dispatch(setLoading(false));
      return data;
    }catch(e:any){
      dispatch(setLoading(false));
      dispatch(setShowAllert({open:true,message:`Ошибка загрузки чата`,type:AlertType.error}as IShowAlert));
    }
  },
);

export const CreateMessage = createAsyncThunk<void, {RequestId:string,Message:string,File?:File}, {
  dispatch: AppDispatch,
  state: State,
  extra: Api
}>(
  'data/CreateMessage',
  async ({RequestId,Message,File}, {dispatch, extra: Services }) => {
    try{
      let data = new FormData();
      data.append("RequestId", RequestId);
      data.append("Message", Message);
      if(File != null && File != undefined)
        data.append("Files", File);
      await Services.Identity.post(APIRequestUser.CreateMessage,data);
      if(File != null && File != undefined){
        window.location.reload();
      }
    }catch(e:any){
      dispatch(setShowAllert({open:true,message:`Ошибка при отправке сообщения`,type:AlertType.error}as IShowAlert));
    }
    
  },
);

export const GetUserRequestOperatorFilter = createAsyncThunk<UserRequest[], {take:string,skip:string,statusId:string,lic:string}, {
  dispatch: AppDispatch,
  state: State,
  extra: Api
}>(
  'data/GetUserRequestOperatorFilter',
  async ({take,skip,statusId,lic}, {dispatch, extra: Services }) => {
    try{
      
      const {data} = await Services.Identity.get(FormatString(APIRequestUser.GetUserRequestOperatorFilter,take,skip,statusId,lic));
      return data;
    }catch(e:any){
      dispatch(setShowAllert({open:true,message:`Ошибка при отправке сообщения`,type:AlertType.error}as IShowAlert));
      return null;
    }
  },
);  
export const SwitchJob = createAsyncThunk<void, {requestId:string,userId:string}, {
  dispatch: AppDispatch,
  state: State,
  extra: Api
}>(
  'data/SwitchJob',
  async ({requestId,userId}, {dispatch, extra: Services }) => {
    try{
     await Services.Identity.get(FormatString(APIRequestUser.SwitchJob,requestId,userId));
    }catch(e:any){
      console.log(e);
      dispatch(setShowAllert({open:true,message:`Произошла ошибка при обработке запроса. Возможно у пользователя нет роли оператора.`,type:AlertType.error}as IShowAlert));
      throw new Error(e);
    }
  },
);
export const RemoveRequest = createAsyncThunk<void, {requestId:string}, {
  dispatch: AppDispatch,
  state: State,
  extra: Api
}>(
  'data/RemoveRequest',
  async ({requestId}, {dispatch, extra: Services }) => {
    try{
      console.log('Удаление ' + requestId);
     await Services.Identity.get(FormatString(APIRequestUser.RemoveRequest,requestId));
    }catch(e:any){
      console.log(e);
      dispatch(setShowAllert({open:true,message:`Произошла ошибка при обработке запроса.`,type:AlertType.error}as IShowAlert));
      throw new Error(e);
    }
  },
);
export const SetGrade = createAsyncThunk<void, {requestId:string,grade:number}, {
  dispatch: AppDispatch,
  state: State,
  extra: Api
}>(
  'data/SetGrade',
  async ({requestId,grade}, {dispatch, extra: Services }) => {
    try{
     await Services.Identity.get(FormatString(APIRequestUser.SetGrade,requestId,grade.toString()));
     dispatch(setShowAllert({ open: true, message: 'Спасибо за оценку', type: AlertType.success } as IShowAlert))
    }catch(e:any){
      console.log(e);
      dispatch(setShowAllert({open:true,message:`Произошла ошибка при обработке запроса.`,type:AlertType.error}as IShowAlert));
      throw new Error(e);
    }
  },
);
export const OperatorTakeOnJob = createAsyncThunk<void, {requestId:string}, {
  dispatch: AppDispatch,
  state: State,
  extra: Api
}>(
  'data/OperatorTakeOnJob',
  async ({requestId}, {dispatch, extra: Services }) => {
    try{
      await Services.Identity.get(FormatString(APIRequestUser.TakeOnJob,requestId));
      
    }catch(e:any){
      dispatch(setShowAllert({open:true,message:`Ошибка при отправке сообщения`,type:AlertType.error}as IShowAlert));
    }
  },
);

export const UpdateStatusUserRequestOperator = createAsyncThunk<void, {statusId:string,requestId:string}, {
  dispatch: AppDispatch,
  state: State,
  extra: Api
}>(
  'data/UpdateStatusUserRequestOperator',
  async ({statusId,requestId}, {dispatch, extra: Services }) => {
    try{
      await Services.Identity.get(FormatString(APIRequestUser.UpdateStatusUserRequestOperator,statusId,requestId));
    }catch(e:any){
      dispatch(setShowAllert({open:true,message:`Ошибка при отправке сообщения`,type:AlertType.error}as IShowAlert));

    }
  },
);

export const GetFileRequestMessage = createAsyncThunk<void, {messageId:string,fileName:string}, {
  dispatch: AppDispatch,
  state: State,
  extra: Api
}>(
  'data/GetFileRequestMessage',
  async ({messageId,fileName}, {dispatch, extra: Services }) => {
    try{
      if(valueIsNullOrUndefined(messageId,'') == ''){
        dispatch(setShowAllert({open:true,message:`Не указана дата.`,type:AlertType.error}as IShowAlert));
        return;
      }
        dispatch(setLoading(true));

        const res =  await Services.Identity.get(FormatString(`${APIRequestUser.GetFileRequestMessage}`,messageId), { responseType: "blob" });
        
        
        let url = window.URL
          .createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${fileName}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);


      dispatch(setLoading(false));
    }catch(e:any){
      console.log(e);
      dispatch(setLoading(false));
      dispatch(setShowAllert({open:true,message:`Произошла ошибка при обработке запроса.`,type:AlertType.error}as IShowAlert));
      throw new Error(e);
    }
  },
);

