import {
  Autocomplete,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../Hooks";
import {
  AddLicForUserAdmin,
  AddUserRole,
  GetAllRoles,
  GetUserRoles,
  RemoveUser,
  RemoveUserRole,
  SearchUserFio,
} from "../../../services/api-actions/user-action";
import {
  getAllRoles,
  getSearchUserByFio,
} from "../../../store/user-process/selector";
import { useEffect, useId, useState } from "react";
import { IUserInfo } from "../../../types/user-info/user-info";

const Div = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1),
  textAlign: "center",
}));

export default function Roles() {
  const colWidth = { xs: 12, sm: 4, md: 4, lg: 4 } as const;

  const dispatch = useAppDispatch();
  const [searchValue, setSearchValue] = useState("");
  const [selectUserInfo, setSelectUserInfo] = useState({} as IUserInfo);
  const [selectUserRole, setSelectUserRole] = useState({
    userId: "",
    role: "",
  });

  const searchUser = useAppSelector(getSearchUserByFio)?.map((item) => {
    return { label: item.fio, id: item.userId };
  });
  const allRoles = useAppSelector(getAllRoles);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch(SearchUserFio({ fio: searchValue }));
    }, 2000);
    return () => clearTimeout(timeoutId);
  }, [searchValue]);
  useEffect(() => {
    dispatch(GetAllRoles());
  }, [dispatch]);
  console.log(selectUserInfo);
  return (
    <>
      <Grid container spacing={2}>
        <Grid item {...colWidth}>
          <Autocomplete
            disablePortal
            noOptionsText={"Ничего не найдено"}
            id="combo-box-demo"
            options={searchUser ?? []}
            onChange={(event, newValue) => {
              setSelectUserRole({
                ...selectUserRole,
                userId: newValue?.id ?? "",
              });
              if (newValue?.id !== undefined) {
                dispatch(GetUserRoles({ userId: newValue?.id ?? "" })).then(
                  (data: any) => {
                    setSelectUserInfo(data.payload as IUserInfo);
                  }
                );
              } else {
                setSelectUserInfo({} as IUserInfo);
              }
            }}
            onInputChange={(event: object, value: any) => {
              setSearchValue(value);
            }}
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} label="Пользователь" />
            )}
          />
        </Grid>
        <Grid item {...colWidth}>
          <FormControl sx={{ minWidth: "100%" }}>
            <InputLabel id="demo-simple-select-autowidth-label">
              Выберите роль
            </InputLabel>
            <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              value={selectUserRole.role}
              onChange={(e: any) => {
                setSelectUserRole({
                  ...selectUserRole,
                  role: e.target.value ?? "",
                });
              }}
              autoWidth
              label="Выберите роль"
            >
              {allRoles?.map((item) => {
                return (
                  <MenuItem key={item.name} value={item.name}>
                    {item.description}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item {...colWidth}>
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              dispatch(
                AddUserRole({
                  UserId: selectUserRole.userId,
                  RoleName: selectUserRole.role,
                })
              ).then((data: any) => {
                dispatch(GetUserRoles({ userId: selectUserRole.userId })).then(
                  (data: any) => {
                    setSelectUserInfo(data.payload as IUserInfo);
                  }
                );
              });
            }}
          >
            Добавить
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        {selectUserInfo.fio !== undefined && (
          <>
            <Grid
              sx={{ mt: "20px", mb: "20px" }}
              item
              {...{ xs: 12, sm: 12, md: 12, lg: 12 }}
            >
              <Div>
                {"Роли пользователя:"} <b>{selectUserInfo.fio}</b>
                <Button
                  variant="contained"
                  style={{ marginLeft: "20px" }}
                  color="error"
                  onClick={() => {
                    dispatch(RemoveUser({ UserId: selectUserInfo.userId }));
                  }}
                >
                  Удалить пользователя
                </Button>
              </Div>
            </Grid>

            {selectUserInfo?.roles?.map((item) => {
              return (
                <>
                  <Grid item {...colWidth}>
                    <Typography>{selectUserInfo.fio}</Typography>
                  </Grid>
                  <Grid item {...colWidth}>
                    <Typography>{item}</Typography>
                  </Grid>
                  <Grid item {...colWidth}>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => {
                        dispatch(
                          RemoveUserRole({
                            UserId: selectUserRole.userId,
                            RoleName: item,
                          })
                        ).then((data: any) => {
                          dispatch(
                            GetUserRoles({ userId: selectUserRole.userId })
                          ).then((data: any) => {
                            setSelectUserInfo(data.payload as IUserInfo);
                          });
                        });
                      }}
                    >
                      удалить
                    </Button>
                  </Grid>
                  <Grid item {...{ xs: 12, sm: 12, md: 12, lg: 12 }}>
                    <Divider sx={{ mt: "20px", mb: "20px" }}></Divider>
                  </Grid>
                </>
              );
            })}
          </>
        )}
      </Grid>
    </>
  );
}
