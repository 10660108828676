import axios, {AxiosError, AxiosInstance, AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse} from 'axios';
import {StatusCodes} from 'http-status-codes';
import {toast} from 'react-toastify';
import authService from '../Components/api-authorization/AuthorizeService';
import { AppRoute } from '../enums/enum';

interface AdaptAxiosRequestConfig extends AxiosRequestConfig {
  headers: AxiosRequestHeaders
}
interface AdaptAxiosRequestError extends AxiosError {
  response: AxiosResponse
}

const StatusCodeMapping: Record<number, boolean> = {
  [StatusCodes.BAD_REQUEST]: true,
  [StatusCodes.UNAUTHORIZED]: true,
  [StatusCodes.NOT_FOUND]: true
};

const shouldDisplayError = (response: AxiosResponse) => !!StatusCodeMapping[response.status];
const REQUEST_TIMEOUT = 600000;

const BACKEND_URL_Identity = `/`;



export const createApiIdentity = (): AxiosInstance => {
  const api = axios.create({
    baseURL: BACKEND_URL_Identity,
    timeout: REQUEST_TIMEOUT,
  });
  api.interceptors.request.use(
    (config: AdaptAxiosRequestConfig) => {
      config.withCredentials = true;
      config.headers['Access-Control-Allow-Origin'] = '*';
      config.headers["Access-Control-Allow-Methods"] = "GET,PUT,POST,DELETE,PATCH,OPTIONS";
      return config;
    }
  );
  api.interceptors.response.use(
    (response) => response,
    (error: AdaptAxiosRequestError) => {
      if (error.response && shouldDisplayError(error.response)) {
        //window.location.href = AppRoute.RefreshToken;
        toast.warn(error.response.data.error);
      }

      throw error;
    }
  );
  return api;
};


const BACKEND_URL_INTEGRATION = process.env.REACT_APP_URL_INTEGRATION;

export const createApiIntegration = (): AxiosInstance => {
console.log(BACKEND_URL_INTEGRATION);
  const api = axios.create({
    baseURL: `${process.env.REACT_APP_URL_PROTOLOC}://${BACKEND_URL_INTEGRATION}`,
    timeout: REQUEST_TIMEOUT,
  });
  api.interceptors.request.use(
    (config: AdaptAxiosRequestConfig) => {
      config.withCredentials = true;
      config.headers['Access-Control-Allow-Origin'] = '*';
      config.headers["Access-Control-Allow-Methods"] = "GET,PUT,POST,DELETE,PATCH,OPTIONS";
      return config;
    }
  );
  api.interceptors.response.use(
    (response) => {
      console.log(response);
      return response;
    },
    (error: AdaptAxiosRequestError) => {
      if (error.response && shouldDisplayError(error.response)) {
        toast.warn(error.response.data.error);
      }
      throw error;
    }
  );
  return api;
};
