import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppDispatch, State } from "../../types/state";
import { APIRequestUser, APIRouteUser, AlertType, LocalStorageMethod } from "../../enums/enum";
import { Api } from "../../store/index";
import { IShowAlert, IUserInfo, IRoles } from "../../types/user-info/user-info";
import { setShowAllert } from "../../store/user-process/user-process";
import LocalStorageProvider from "../../utils/locaStorageProvider";
import valueIsNullOrUndefined from "../../utils/valueOrDefault";
import { setLoading } from "../../store/main-process/main-process";
import { FormatString } from "../../utils/stringHelper";
import { Solution } from "../../Components/dialog/lic/QueryElectricalReceipt";

export const GetUserInfo = createAsyncThunk<
  IUserInfo,
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: Api;
  }
>("data/GetUserInfo", async (_arg, { extra: Services }) => {
  const { data } = await Services.Identity.get(APIRouteUser.UserInfo);
  console.log("Получение информации о пользователе " + data.fio);
  return data;
});
export const DeleteFullLicAtAccount = createAsyncThunk<
  void,
  { userId: string; fullLic: string },
  {
    dispatch: AppDispatch;
    state: State;
    extra: Api;
  }
>("data/DeleteFullLicAtAccount", async ({ userId, fullLic }, { dispatch, extra: Services }) => {
  try {
    await Services.Identity.get(`${APIRouteUser.DeleteFullLicAtAccount}?UserId=${userId}&FullLic=${fullLic}`);
    dispatch(setShowAllert({ open: true, message: `Лицевой счет ${fullLic} успешно удален`, type: AlertType.success } as IShowAlert));
  } catch {
    dispatch(setShowAllert({ open: true, message: `Ошибка при удаление лецевого счета ${fullLic}`, type: AlertType.error } as IShowAlert));
  }
});
export const AddFullLicAtAccount = createAsyncThunk<
  void,
  { userId: string; fullLic: string; IndicationDate: Date; IndicationValue: number },
  {
    dispatch: AppDispatch;
    state: State;
    extra: Api;
  }
>("data/AddFullLicAtAccount", async ({ userId, fullLic, IndicationDate, IndicationValue }, { dispatch, extra: Services }) => {
  try {
    if (fullLic.length == 0) throw new Error("Не указан лицевой счет");
    if (IndicationValue == 0 || IndicationDate == new Date("1970-01-01")) throw new Error("Укажите хотябы дату последнего платежа или последние показания");

    await Services.Identity.post(`${APIRouteUser.AddFullLicAtAccount}`, { userId, fullLic, IndicationDate, IndicationValue });
    dispatch(setShowAllert({ open: true, message: `Запрос поступит на электронную почту в течении 5 минут`, type: AlertType.success, time: 10000 } as IShowAlert));
  } catch (e: any) {
    dispatch(setShowAllert({ open: true, message: `${e.response.data}`, type: AlertType.error, time: 10000 } as IShowAlert));
    throw new Error(e);
  }
});
export const AddQueueReceipt = createAsyncThunk<
  void,
  { Email: string; fullLic: string; startDate: string; endDate: string },
  {
    dispatch: AppDispatch;
    state: State;
    extra: Api;
  }
>("data/AddQueueReceipt", async ({ Email, fullLic, startDate, endDate }, { dispatch, extra: Services }) => {
  try {
    if (fullLic.length == 0) throw new Error("Не выбран лицевой счет");
    if (startDate.length == 0 || endDate.length == 0) throw new Error("Укажите дату с и по");

    await Services.Identity.get(`${APIRouteUser.AddQueueReceipt}?Email=${Email}&StartDate=${startDate}&EndDate=${endDate}&Lic=${fullLic}`);
    dispatch(
      setShowAllert({
        open: true,
        message: `Мы отправили запрос на формирование квитанций. Дождитесь уведомление на электронную почту ${Email}`,
        type: AlertType.success,
      } as IShowAlert)
    );
  } catch (e: any) {
    console.log(e);
    dispatch(setShowAllert({ open: true, message: `${e.response.data}`, type: AlertType.error } as IShowAlert));
    throw new Error(e);
  }
});
export const ChangeElectronicReceipts = createAsyncThunk<
  void,
  { action: string; email: string; lic: string },
  {
    dispatch: AppDispatch;
    state: State;
    extra: Api;
  }
>("data/ChangeElectronicReceipts", async ({ action, email, lic }, { dispatch, extra: Services }) => {
  try {
    if (valueIsNullOrUndefined(email, "") == "" && action != Solution.Refuse) {
      dispatch(setShowAllert({ open: true, message: `Укажите email.`, type: AlertType.error } as IShowAlert));
      return;
    }
    await Services.Identity.get(`${APIRouteUser.ChangeElectronicReceipts}?Action=${action}&Email=${email}&Lic=${lic}`);
    dispatch(setShowAllert({ open: true, message: `Мы отправили запрос`, type: AlertType.success } as IShowAlert));
  } catch (e: any) {
    console.log(e);
    dispatch(setShowAllert({ open: true, message: `Произошла ошибка при обработке запроса.`, type: AlertType.error } as IShowAlert));
    throw new Error(e);
  }
});
export const RequestInaccuracyPu = createAsyncThunk<
  void,
  { Email: string; Text: string; TypePu: string; NumberPu: string; UserId: string; File: File[] },
  {
    dispatch: AppDispatch;
    state: State;
    extra: Api;
  }
>("data/RequestInaccuracyPu", async ({ Email, Text, TypePu, NumberPu, UserId, File }, { dispatch, extra: Services }) => {
  try {
    let data = new FormData();
    let defaultLic = LocalStorageProvider(UserId, null, LocalStorageMethod.getItem);
    data.append("Email", Email);
    data.append("Text", Text);
    data.append("TypePu", TypePu);
    data.append("NumberPu", NumberPu);
    data.append("Lic", defaultLic.lic);
    File.map((item) => data.append("Files", item));
    await Services.Identity.post(APIRequestUser.InaccuracyPu, data);
    dispatch(setShowAllert({ open: true, message: `Мы отправили запрос. Дальше преписка будет в почте.`, type: AlertType.success } as IShowAlert));
  } catch (e: any) {
    console.log(e);
    dispatch(setShowAllert({ open: true, message: `${e.response.data}`, type: AlertType.error } as IShowAlert));
    throw new Error(e);
  }
});

export const SearchUserFio = createAsyncThunk<
  IUserInfo[],
  { fio: string },
  {
    dispatch: AppDispatch;
    state: State;
    extra: Api;
  }
>("data/SearchUserFio", async ({ fio }, { dispatch, extra: Services }) => {
  try {
    /* if(valueIsNullOrUndefined(fio,'') == '')
          return null; */

    const { data } = await Services.Identity.get(`${APIRouteUser.SearchUser}?FIO=${fio ?? ""}`);
    return data;
  } catch (e: any) {
    console.log(e);
    dispatch(setShowAllert({ open: true, message: `Произошла ошибка при обработке запроса.`, type: AlertType.error } as IShowAlert));
    throw new Error(e);
  }
});

export const GetAllRoles = createAsyncThunk<
  IRoles[],
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: Api;
  }
>("data/GetAllRoles", async (_arg, { dispatch, extra: Services }) => {
  try {
    const { data } = await Services.Identity.get(`${APIRouteUser.GetAllRoles}`);
    return data;
  } catch (e: any) {
    console.log(e);
    dispatch(setShowAllert({ open: true, message: `Произошла ошибка при обработке запроса.`, type: AlertType.error } as IShowAlert));
    throw new Error(e);
  }
});

export const GetUserRoles = createAsyncThunk<
  IUserInfo,
  { userId: string },
  {
    dispatch: AppDispatch;
    state: State;
    extra: Api;
  }
>("data/GetUserRoles", async ({ userId }, { dispatch, extra: Services }) => {
  try {
    const { data } = await Services.Identity.get(FormatString(`${APIRouteUser.GetUserRoles}`, userId));
    return data;
  } catch (e: any) {
    console.log(e);
    dispatch(setShowAllert({ open: true, message: `Произошла ошибка при обработке запроса.`, type: AlertType.error } as IShowAlert));
    throw new Error(e);
  }
});

export const AddUserRole = createAsyncThunk<
  void,
  { UserId: string; RoleName: string },
  {
    dispatch: AppDispatch;
    state: State;
    extra: Api;
  }
>("data/AddUserRole", async ({ UserId, RoleName }, { dispatch, extra: Services }) => {
  try {
    await Services.Identity.get(FormatString(`${APIRouteUser.AddUserRole}`, UserId, RoleName));
    dispatch(setShowAllert({ open: true, message: `Роль успешно добавлена.`, type: AlertType.success } as IShowAlert));
  } catch (e: any) {
    console.log(e);
    dispatch(setShowAllert({ open: true, message: `Произошла ошибка при обработке запроса.`, type: AlertType.error } as IShowAlert));
    throw new Error(e);
  }
});
export const RemoveUserRole = createAsyncThunk<
  void,
  { UserId: string; RoleName: string },
  {
    dispatch: AppDispatch;
    state: State;
    extra: Api;
  }
>("data/RemoveUserRole", async ({ UserId, RoleName }, { dispatch, extra: Services }) => {
  try {
    await Services.Identity.get(FormatString(`${APIRouteUser.RemoveUserRole}`, UserId, RoleName));
    dispatch(setShowAllert({ open: true, message: `Роль успешно удалена.`, type: AlertType.success } as IShowAlert));
  } catch (e: any) {
    console.log(e);
    dispatch(setShowAllert({ open: true, message: `Произошла ошибка при обработке запроса.`, type: AlertType.error } as IShowAlert));
    throw new Error(e);
  }
});
export const RemoveUser = createAsyncThunk<
  void,
  { UserId: string },
  {
    dispatch: AppDispatch;
    state: State;
    extra: Api;
  }
>("data/RemoveUser", async ({ UserId }, { dispatch, extra: Services }) => {
  try {
    await Services.Identity.get(FormatString(`${APIRouteUser.RemoveUser}`, UserId));
    dispatch(setShowAllert({ open: true, message: `Пользователь успешно удален.`, type: AlertType.success } as IShowAlert));
  } catch (e: any) {
    console.log(e);
    dispatch(setShowAllert({ open: true, message: `Произошла ошибка при обработке запроса.`, type: AlertType.error } as IShowAlert));
    throw new Error(e);
  }
});

export const AddLicForUserAdmin = createAsyncThunk<
  void,
  { UserId: string; Lic: string },
  {
    dispatch: AppDispatch;
    state: State;
    extra: Api;
  }
>("data/AddLicForUserAdmin", async ({ UserId, Lic }, { dispatch, extra: Services }) => {
  try {
    if (valueIsNullOrUndefined(UserId, "") == "") {
      dispatch(setShowAllert({ open: true, message: `Не указан пользователь.`, type: AlertType.error } as IShowAlert));
      return;
    }
    if (valueIsNullOrUndefined(Lic, "") == "") {
      dispatch(setShowAllert({ open: true, message: `Не указан лицевой счет.`, type: AlertType.error } as IShowAlert));
      return;
    }

    dispatch(setLoading(true));

    await Services.Identity.post(`${APIRouteUser.AddLicForUser}`, { UserId, Lic });

    dispatch(setShowAllert({ open: true, message: `Лицевой счет успешно добавлен.`, type: AlertType.success } as IShowAlert));
    dispatch(setLoading(false));
  } catch (e: any) {
    console.log(e);
    dispatch(setLoading(false));
    dispatch(setShowAllert({ open: true, message: `Произошла ошибка при обработке запроса. Возможно не найден такой лицевой счета в базе.`, type: AlertType.error } as IShowAlert));
    throw new Error(e);
  }
});
