import { Button, Card, CardActions,  CardContent, CardMedia, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Typography, styled } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import React, { useState } from "react";
import ApiAuthorzationRoutes from '../../api-authorization/ApiAuthorizationRoutes';
import { useAppDispatch, useAppSelector } from "../../../Hooks";
import { AddFullLicAtAccount, DeleteFullLicAtAccount, GetUserInfo } from "../../../services/api-actions/user-action";
import { getUserInfo } from "../../../store/user-process/selector";
import useWindowDimensions from "../../../Hooks/windowDimensions";
import AuthorizeRoute from "../../api-authorization/AuthorizeRoute";
import Main from "../../../Page/Main/Main";
import ShowAlert from "../../allert/alert";
import { Link } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import { AppRoute, LocalStorageMethod } from "../../../enums/enum";
import authService from '../../api-authorization/AuthorizeService';
import LocalStorageProvider from "../../../utils/locaStorageProvider";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

type SettingModel={
  Open:boolean,
  Close:React.Dispatch<React.SetStateAction<boolean>>,
  Title:string,
  Lic:string,
  UserId:string
}

export default function DeleteLic(settingModel:SettingModel){
  const { height, width } = useWindowDimensions();
  let MinWidth = width < 800 ? '300px' : '230px';
  const [addLicForUser,setAddLicForUser] = useState({
    fullLic: '',
    indicationDate: new Date("1970-01-01"),
    indicationValue: 0,
  });
  const userInfo = useAppSelector(getUserInfo);
  const dispatch = useAppDispatch();
 // React.useEffect(() => {
   // localStorage.setItem('token', JSON.stringify(accessToken));
 //   dispatch(GetUserInfo());
 // }, [dispatch]);
  return(
  <>
   <React.Fragment key={settingModel.Lic}>
      <BootstrapDialog
        onClose={(e)=>settingModel.Close(false)}
        aria-labelledby="customized-dialog-title"
        open={settingModel.Open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Удаление лицевого счета
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={(e)=>settingModel.Close(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Card className=''>
            <CardMedia style={{padding: '10px'}}>
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
              {settingModel.Title}
            </DialogTitle>
            </CardMedia>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  <Button variant="contained" color="error"
                    onClick={()=>{
                      dispatch(DeleteFullLicAtAccount({userId:settingModel.UserId, fullLic:settingModel.Lic}));
                      LocalStorageProvider(userInfo.userId,settingModel.Lic,LocalStorageMethod.removeItem);
                      window.location.reload();
                      settingModel.Close(false);
                    }}
                  >
                    Удалить
                  </Button>
                  <Button variant="contained" color="success" autoFocus onClick={(e)=>settingModel.Close(false)}>
                    Отмена
                  </Button>
                </Typography>
              </CardContent>
            <CardActions>
            </CardActions>
          </Card>
      </DialogContent>
        <DialogActions>
          
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  </>
  )
}