import { Box, Button, Card, CardActions,  CardContent, CardMedia, FormControl, InputLabel, MenuItem, Paper, Select, Step, StepContent, StepLabel, Stepper, TextField, Typography } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import React, { useState } from "react";
import ApiAuthorzationRoutes from '../../api-authorization/ApiAuthorizationRoutes';
import { useAppDispatch, useAppSelector } from "../../../Hooks";
import { AddFullLicAtAccount, GetUserInfo } from "../../../services/api-actions/user-action";
import { getUserInfo } from "../../../store/user-process/selector";
import useWindowDimensions from "../../../Hooks/windowDimensions";
import AuthorizeRoute from "../../api-authorization/AuthorizeRoute";
import Main from "../../../Page/Main/Main";
import { AlertType, AppRoute, LocalStorageMethod } from "../../../enums/enum";
import { Link } from "react-router-dom";
import { getPersonalInfo } from "../../../store/integration-process/selector";
import { countReset } from "console";
import LocalStorageProvider from "../../../utils/locaStorageProvider";
import ShowAlert from "../../allert/alert";
import { setShowAllert } from "../../../store/user-process/user-process";
import { IShowAlert } from "../../../types/user-info/user-info";
import { WriteIpuReadings } from "../../../services/api-actions/integration-action";

export default function AddIndinications(){
  const { height, width } = useWindowDimensions();
  let MinWidth = width > 800 ? '400px' : '230px';

  const [counter, setCounter] = React.useState({
    typePu:'',
    readings: -1
  });

  const personalInfo = useAppSelector(getPersonalInfo);

  const userInfo = useAppSelector(getUserInfo);
  let defaultLic = LocalStorageProvider(userInfo.userId,null,LocalStorageMethod.getItem);

  console.log(defaultLic);
  const dispatch = useAppDispatch();
  
  const [step, setStep] = useState(0);
  const [activeStep, setActiveStep] = React.useState(0);

  const sendReaings = () => {
    try{
      dispatch(WriteIpuReadings({lic:defaultLic.lic, typePu: counter.typePu,readings:counter.readings}));
      setActiveStep((prevActiveStep) => {
        setStep(step+1);
        return prevActiveStep;
      });
    }catch{

    }
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => {
      setStep(step+1);
      return prevActiveStep;
    });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      setStep(step-1);
      return prevActiveStep;
    });
  };

  const handleReset = () => {
    setStep(0);
  };
  return (
    <Box sx={{ maxWidth: 400, minWidth: MinWidth }}>
      <Stepper activeStep={step} orientation="vertical">
          <Step key={1}>
            <StepLabel
              optional={
                  <Typography variant="caption">Первый шаг</Typography>
              }
            >
              {'Выберите прибор учета'}
            </StepLabel>
            <StepContent>
              <Box sx={{ mb: 2 }}>
                <div>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Прибор учета</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={counter.typePu}
                      label="Прибор учета"
                      onChange={(e:any)=>{ 
                        setCounter({typePu:e.target.value,readings:counter.readings});
                      }}
                    >
                      {
                        personalInfo.counters.map((item,index)=> <MenuItem value={item.typePu}>{item.typePu} последние показания {item.lastReadings}</MenuItem>)
                      }
                    </Select>
                  </FormControl>
                  {counter.typePu!= '' &&
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      {'Продолжить'}
                    </Button>
                  }
                  <Button
                    disabled={step === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Назад
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
          <Step key={2}>
            <StepLabel
              optional={
                  <Typography variant="caption">Второй шаг</Typography>
              }
            >
              {'Внесите показания'}
            </StepLabel>
            <StepContent>
            <TextField 
              inputProps={{ type: 'number'}}
              label="Последние показания"
              value={counter.readings == -1 ? personalInfo.counters.find(x=>x.typePu == counter.typePu)?.lastReadings : counter.readings}
              onChange={(e:any)=>{
                  setCounter({typePu:counter.typePu,readings:e.target.value});
              }}
            />
              <Box sx={{ mb: 2 }}>
                <div>
                  {counter.readings >0 &&
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      {'Продолжить'}
                    </Button>
                  }
                  <Button
                    disabled={step === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Назад
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
          <Step key={3}>
            <StepLabel
              optional={
                  <Typography variant="caption">Последний шаг</Typography>
              }
            >
              {'Отправка показаний'}
            </StepLabel>
            <StepContent>
              <Box sx={{ mb: 2 }}>
                <div>
                  {Number(personalInfo.counters.find(x=>x.typePu == counter.typePu)?.lastReadings) > counter.readings &&
                  <Typography style={{color:'red'}} >Внимание !!! Ваши показания ({counter.readings}) меньше предыдущих ({personalInfo.counters.find(x=>x.typePu == counter.typePu)?.lastReadings})</Typography>
                  }
                  {Number(personalInfo.counters.find(x=>x.typePu == counter.typePu)?.lastReadings) <= counter.readings &&
                    <>
                      <Typography style={{color:'red'}} >Проверьте внесенные данные по лицевому счету {defaultLic?.lic}</Typography>
                      <Typography>Прибор учета: {counter.typePu}</Typography>
                      <Typography>Показания: {counter.readings}</Typography>
                      <Button
                        variant="contained"
                        onClick={sendReaings}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        {'Отправить'}
                      </Button>
                    </>
                  }
                  <Button
                    disabled={step === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Назад
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
      </Stepper>
      {step === 3 && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>Спасибо мы передали информацию</Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Внести еще показания
          </Button>
        </Paper>
      )}
    </Box>
  );
}
function toJSONLocal(date:Date) {
  var local = new Date(date);
  local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  return local.toJSON().slice(0, 10);
}
