import { Box, Button, Input, Typography } from "@mui/material";
import useWindowDimensions from "../../Hooks/windowDimensions";

import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { createContext, useEffect, useState } from "react";
import useSignalR from "./useSignalR";
import { Console } from "console";
import { buildConnection, startConnection } from "../../utils/hubUtils";

export interface Message {
  username: string
  message: string
}

interface IHubContext {
  connection?: HubConnection
  connectionStarted: boolean

  messages: Message[]
  connectedUsers: string[]
}

export const HubContext = createContext<IHubContext | null>(null)


export default function Test(){
  const { height, width } = useWindowDimensions();

  const [connection, setConnection] = useState<HubConnection>();
  const [messages, setMessages] = useState<Message[]>([]);
  const [connectedUsers, setConnectedUsers] = useState<string[]>([]);
  const [connectionStarted, setConnectionStarted] = useState(false);
 
  const startNewConnection = () => {
    const newConnection = buildConnection();

    setConnection(newConnection);
}

useEffect(() => {
    startNewConnection();
}, []);

 useEffect(() => {

  if (connection) {
      startConnection(connection)
          .then(() => {
              setConnectionStarted(true)

             

              connection.onclose(() => {
                  startNewConnection();
              });
          });
  }
}, [connection]); 
//connection?.invoke('JoinRoom',{ userName: 'Test', RoomId:'Test' })
console.log(connection);
/* if (connection) {
  connection?.invoke('SendMessage' , 'Test');
} */
  return(
    <HubContext.Provider value={{ connection, connectionStarted,messages, connectedUsers }}>
    <Box sx={{ flexGrow: 2, p: width < 595 ? 2 : 10 }}>        
      <Typography gutterBottom variant="h4" component="div" align='left'>
      <h1>SignalR Chat</h1>
      <p>{connection ? "Connected" : "Not connected"}</p>
      </Typography>
    </Box>
    </HubContext.Provider>
  );
}