import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppDispatch, State } from "../../types/state";
import { APIRouteIntegration, AlertType, AppRoute } from "../../enums/enum";
import { Api } from "../../store/index";
import authService from "../../Components/api-authorization/AuthorizeService";
import { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { IPersonalInfo } from "../../types/type-integration/persona-info";
import { FormatString } from "../../utils/stringHelper";
import { IReadingHistory } from "../../types/type-integration/reading-history";
import { setShowAllert } from "../../store/user-process/user-process";
import { IShowAlert } from "../../types/user-info/user-info";
import { IWriteIpuReadings } from "../../types/type-integration/writeIpu-readings";
import IUserLicAddresses from "../../types/type-integration/userLicAddresses";
import IPayments from "../../types/type-integration/payments-history";
import { setLoading } from "../../store/main-process/main-process";
import valueIsNullOrUndefined from "../../utils/valueOrDefault";

interface AdaptAxiosRequestConfig extends AxiosRequestConfig {
  headers: AxiosRequestHeaders;
}

async function AddBearerToken(Services: Api) {
  const user = await authService.getUser();
  const token = await authService.getAccessToken();
  var seconds = Math.floor(Date.now() / 1000);
  if (seconds > user.auth_time + 21600) {
    window.location.href = AppRoute.RefreshToken;
  }
  Services.Integrations.interceptors.request.use((config: AdaptAxiosRequestConfig) => {
    config.headers["Authorization"] = `Bearer ${token}`;
    return config;
  });
}

export const GetFullInfoByLic = createAsyncThunk<
  IPersonalInfo,
  { fullLic: string },
  {
    dispatch: AppDispatch;
    state: State;
    extra: Api;
  }
>("data/GetFullInfoByLic", async ({ fullLic }, { dispatch, extra: Services }) => {
  try {
    dispatch(setLoading(true));
    await AddBearerToken(Services);
    const { data } = await Services.Integrations.get(FormatString(APIRouteIntegration.GetFullInfoByLic, fullLic));
    dispatch(setLoading(false));
    return data;
  } catch (e: any) {
    dispatch(setLoading(false));
    if (e.response.status == 401) {
      const user = await authService.getUser();
      var seconds = Math.floor(Date.now() / 1000);
      if (seconds > user.auth_time + 21600) {
        window.location.href = AppRoute.RefreshToken;
      }
    }
  }
});

export const GetReadingHistory = createAsyncThunk<
  IReadingHistory[],
  { fullLic: string; typePu: string; from: string; to: string },
  {
    dispatch: AppDispatch;
    state: State;
    extra: Api;
  }
>("data/GetReadingHistory", async ({ fullLic, typePu, from, to }, { dispatch, extra: Services }) => {
  try {
    if (typePu.length == 0) {
      dispatch(
        setShowAllert({
          open: true,
          message: "Не выбран прибор учета",
          type: AlertType.error,
        } as IShowAlert)
      );
      throw new Error("Не выбран прибор учета");
    }
    if (from == "Invalid Date") {
      dispatch(
        setShowAllert({
          open: true,
          message: "Не указана период",
          type: AlertType.error,
        } as IShowAlert)
      );
      throw new Error("Не указана начальная дата");
    }

    await AddBearerToken(Services);
    const { data } = await Services.Integrations.get(FormatString(APIRouteIntegration.GetReadingHistory, fullLic, typePu, from, to));

    return data;
  } catch (e: any) {
    dispatch(
      setShowAllert({
        open: true,
        message: `${e.response.data}`,
        type: AlertType.error,
      } as IShowAlert)
    );
    throw new Error(e);
  }
});
export const GetPayments = createAsyncThunk<
  IPayments[],
  { fullLic: string },
  {
    dispatch: AppDispatch;
    state: State;
    extra: Api;
  }
>("data/GetPayments", async ({ fullLic }, { dispatch, extra: Services }) => {
  try {
    dispatch(setLoading(true));
    await AddBearerToken(Services);
    const { data } = await Services.Integrations.get(FormatString(APIRouteIntegration.GetPayments, fullLic));
    dispatch(setLoading(false));
    return data;
  } catch (e: any) {
    dispatch(
      setShowAllert({
        open: true,
        message: `${e.response.data}`,
        type: AlertType.error,
      } as IShowAlert)
    );
    dispatch(setLoading(false));
    throw new Error(e);
  }
});
export const WriteIpuReadings = createAsyncThunk<
  void,
  IWriteIpuReadings,
  {
    dispatch: AppDispatch;
    state: State;
    extra: Api;
  }
>("data/WriteIpuReadings", async ({ lic, typePu, readings }: IWriteIpuReadings, { dispatch, extra: Services }) => {
  console.log("Send Readings");
  try {
    if (typePu.length == 0) {
      dispatch(
        setShowAllert({
          open: true,
          message: "Не выбран прибор учета",
          type: AlertType.error,
        } as IShowAlert)
      );
      throw new Error("Не выбран прибор учета");
    }
    if (readings == 0) {
      dispatch(
        setShowAllert({
          open: true,
          message: "Не возможно передать нулевые показания",
          type: AlertType.error,
        } as IShowAlert)
      );
      throw new Error("Не выбран прибор учета");
    }
    dispatch(setLoading(true));
    await AddBearerToken(Services);
    await Services.Integrations.post(APIRouteIntegration.WriteIpuReadings, {
      lic,
      typePu,
      readings,
    });

    dispatch(
      setShowAllert({
        open: true,
        message: "Показания переданы успешно",
        type: AlertType.success,
      } as IShowAlert)
    );
    dispatch(setLoading(false));
  } catch (e: any) {
    dispatch(
      setShowAllert({
        open: true,
        message: `${e.response.data}`,
        type: AlertType.error,
      } as IShowAlert)
    );
    dispatch(setLoading(false));
    throw new Error(e);
  }
});
export const GetUserLicAddresses = createAsyncThunk<
  IUserLicAddresses[],
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: Api;
  }
>("data/GetUserLicAddresses", async (_arg, { extra: Services }) => {
  await AddBearerToken(Services);
  const { data } = await Services.Integrations.get(APIRouteIntegration.GetUserLicAddresses);
  return data;
});
export const GetPeriodStatus = createAsyncThunk<
  boolean,
  undefined,
  {
    dispatch: AppDispatch;
    state: State;
    extra: Api;
  }
>("data/GetPeriodStatus", async (_arg, { extra: Services }) => {
  await AddBearerToken(Services);
  const { data } = await Services.Integrations.get(APIRouteIntegration.GetPeriodStatus);
  return data;
});
export const GetReadings
= createAsyncThunk<
  void,
  { BeginningDate: string },
  {
    dispatch: AppDispatch;
    state: State;
    extra: Api;
  }
>("data/GetReadings", async ({ BeginningDate }, { dispatch, extra: Services }) => {
  try {
    if (valueIsNullOrUndefined(BeginningDate, "") == "") {
      dispatch(
        setShowAllert({
          open: true,
          message: `Не указана дата.`,
          type: AlertType.error,
        } as IShowAlert)
      );
      return;
    }
    dispatch(setLoading(true));
    await AddBearerToken(Services);
    const res = await Services.Integrations.get(FormatString(`${APIRouteIntegration.GetReadings}`, BeginningDate), { responseType: "blob" });

    let url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `Показания из ЛК с ${BeginningDate}.xlsx`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    dispatch(setLoading(false));
  } catch (e: any) {
    console.log(e);
    dispatch(setLoading(false));
    dispatch(
      setShowAllert({
        open: true,
        message: `Произошла ошибка при обработке запроса.`,
        type: AlertType.error,
      } as IShowAlert)
    );
    throw new Error(e);
  }
});
