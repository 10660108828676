import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import useWindowDimensions from "../../Hooks/windowDimensions";
import { getPeriodLockStatus, getPersonalInfo, getReadingHistory } from "../../store/integration-process/selector";
import { useAppDispatch, useAppSelector } from "../../Hooks";
import { Accordion, AccordionDetails, AccordionSummary, Button, CardActions, CardContent, Grid, ListItemText, Paper, styled, TextField, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IndicateAnInaccuracy from "../../Components/dialog/counter/indicate-an-inaccuracy";
import { getUserInfo } from "../../store/user-process/selector";
import WarningIcon from "@mui/icons-material/Warning";
import LocalStorageProvider from "../../utils/locaStorageProvider";
import { AlertType, LocalStorageMethod } from "../../enums/enum";
import { WriteIpuReadings } from "../../services/api-actions/integration-action";
import { setShowAllert } from "../../store/user-process/user-process";
import { IShowAlert } from "../../types/user-info/user-info";

const Div = styled("div")(({ theme }) => ({
  ...theme.typography.button,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1),
  textAlign: "center",
}));

const defaultTheme = createTheme();

export default function SendReadings() {
  const dispatch = useAppDispatch();
  const userInfo = useAppSelector(getUserInfo);
  const lockPeriod = useAppSelector(getPeriodLockStatus);
  let defaultLic = LocalStorageProvider(userInfo.userId, null, LocalStorageMethod.getItem);
  const [data, setData] = useState([
    {
      TypePu: "",
      indinication: "",
    },
  ]);
  const [eror, setEror] = useState({
    TypePu: "",
    Eror: false,
    Message: "",
  });
  const colWidth = { xs: 12, sm: 12, md: 4, lg: 4 } as const;
  const { height, width } = useWindowDimensions();
  const counters = useAppSelector(getPersonalInfo)?.counters;

  return (
    <>
      <ThemeProvider theme={defaultTheme}>
        <Box sx={{ display: "flex" }}>
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) => (theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[900]),
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Toolbar />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }} style={width >= 600 ? { paddingLeft: "70px" } : { paddingLeft: "12px" }}>
              {counters?.length == 0 && (
                <>
                  <Div>{"У вас нет приборов учета"}</Div>
                </>
              )}
              <Grid item {...colWidth}>
                <div style={{ backgroundColor: "#dd9696", padding: "10px" }}>
                  <Typography style={{ textAlign: "center", fontWeight: 900 }}>Уважаемый Абонент! Обращаем Ваше внимание!</Typography>
                  <Typography style={{ fontSize: "12px" }}>
                    В соответствии с Правилами предоставления коммунальных услуг показания счетчиков должны передаваться в Филиал "Пензенский" ПАО "Т Плюс" не позднее 25 числа
                    каждого месяца. В случае передачи показаний после 25 числа, расчет потребленного объема услуг будет произведен исходя из среднего потребления за предыдущие 6
                    месяцев, а переданные показания будут учтены в следующем расчетном периоде для корректировки начисленного по среднему объему. При непредоставлении потребителем
                    показаний индивидуального или общего (квартирного) прибора учета за текущий месяц начисления будут рассчитаны исходя из среднего объема, но не более 3 месяцев
                    подряд, далее - по нормативам потребления коммунальных услуг. При недопуске 2 и более раз потребителем в занимаемое им помещение для проверки состояния
                    установленных и введенных в эксплуатацию индивидуальных, общих (квартирных) приборов учёта, проверки достоверности представленных сведений о показаниях таких
                    приборов учета, плата за коммунальные услуги рассчитывается исходя из нормативов потребления коммунальных услуг с применением повышающих коэффициентов.
                  </Typography>
                </div>
              </Grid>
              <Grid item {...colWidth}>
                {lockPeriod == true && (
                  <Accordion style={{ backgroundColor: "#fff3d0", marginBottom: "20px" }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header" style={{ backgroundColor: "#fff3d0" }}>
                      <WarningIcon style={{ color: "#c8611d" }} /> Период передачи показаний закрыт
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        Мы обязаны использовать в расчетах только показания, переданные до 25-го числа включительно. Поэтому данные, которые Вы передадите сейчас, могут быть не
                        приняты к расчету за текущий месяц.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                )}
                {counters?.length > 0 &&
                  counters.map((item, index) => (
                    <Accordion defaultExpanded={index == 0 ? true : false}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header" style={{ backgroundColor: "#F0F3F4" }}>
                        <ListItemText
                          primary={
                            <div>
                              <span>Прибор учета {item.typePu}</span>
                              <span>&nbsp; {item.dateCheckNext != null ? `Дата следующей поверки ${dayjs(item.dateCheckNext).format("DD.MM.YYYY")}` : ""}</span>
                              <span style={{ float: "right" }}>
                                {item.lastReadingDate != null ? `Предыдущие показания ${dayjs(item.lastReadingDate).format("DD.MM.YYYY")}` : ""}
                              </span>
                            </div>
                          }
                          secondary={item.factoryNumber}
                        />
                      </AccordionSummary>
                      <AccordionDetails>
                        <p>
                          <b>Новые показания</b>
                        </p>
                        <TextField
                          error={eror.TypePu == item.typePu ? eror.Eror : false}
                          id="outlined-number"
                          label=""
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          helperText={`Предыдущие показания ${item.lastReadings}`}
                          onChange={(e: any) => {
                            let pu = data.find((x) => x.TypePu == item.typePu);

                            if (pu == undefined) {
                              data.push({ TypePu: item.typePu, indinication: e.target.value });
                            } else {
                              pu.indinication = e.target.value;
                            }
                            pu = data.find((x) => x.TypePu == item.typePu);
                            if (pu != undefined && Number(pu.indinication) < item.lastReadings) {
                              setEror({ Eror: true, TypePu: item.typePu, Message: "" });
                            } else {
                              setEror({ Eror: false, TypePu: "", Message: "" });
                            }
                          }}
                        />
                        <br></br>
                        {GetStatus(item.dateCheckNext) != "" && <p style={{ color: "red" }}>Срок поверки закончился</p>}
                        {lockPeriod != true && GetStatus(item.dateCheckNext) == "" && (
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => {
                              let pu = data.find((x) => x.TypePu == item.typePu);
                              if (pu != undefined && Number(pu.indinication) >= item.lastReadings) {
                                dispatch(WriteIpuReadings({ lic: defaultLic.lic, typePu: pu.TypePu, readings: Number(pu.indinication) }));
                              } else {
                                dispatch(setShowAllert({ open: true, message: "Новые показния меньше предыдущих", type: AlertType.error } as IShowAlert));
                              }
                            }}
                          >
                            Передать показания
                          </Button>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  ))}
              </Grid>
              <div style={{ backgroundColor: "#dd9696", padding: "10px" }}>
                <Typography style={{ fontSize: "12px" }}>
                  Обратите внимание на номер и дату поверки счетчика (ИПУ) горячей воды/ отопления (при наличии). При несоответствии информации сообщите верную информацию, приложив
                  фото счетчика, паспорт счетчика, акт ввода счетчика/ акт поверки счетчика. В соответствии с Правилами предоставления коммунальных услуг в случае истечения срока
                  эксплуатации ИПУ, определяемого периодом времени до очередной поверки в течение 3-х месяцев расчет платы будет производиться исходя из среднемесячного объема
                  потребления горячей воды. По истечению 3-х месяцев расчет будет производиться исходя из нормативов потребления коммунальной услуги по горячей воде с применением
                  повышающего коэффициента, величина которого принимается равной 1,5. Оснащение помещения ИПУ, ввод в эксплуатацию ИПУ на основании заявки, надлежащая техническая
                  эксплуатация, сохранность и своевременная замена либо проведение поверки ИПУ должны быть обеспечены собственником помещения.
                </Typography>
              </div>
            </Container>
            <div style={width <= 800 ? { marginBottom: "40px", display: "block" } : {}}>_</div>
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
}
function GetStatus(date: string | undefined) {
  if (date == undefined) return "";
  var dateCheckNext = dayjs(date);
  var dates = dayjs();
  var result = dates.diff(dateCheckNext, "month") * -1;
  console.log(result);
  if (result <= 0) {
    return <Typography color="red">Статус: Закончился срок поверки</Typography>;
  }
  return "";
}
