import {configureStore} from '@reduxjs/toolkit';
import {  createApiIdentity, createApiIntegration } from '../services/api';
import { redirect } from './middleware/redirect';
import { rootReducer } from './root-reducer';
import { AxiosInstance } from 'axios';

export const api_Identity = createApiIdentity();
export const Integrations = createApiIntegration();

export type Api = {
  Identity: AxiosInstance;
  Integrations:AxiosInstance;
};


export const ServicesApi:Api = { Identity: api_Identity,Integrations:Integrations};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: ServicesApi,
      },
    }).concat(redirect),
});
