import * as React from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import TelegramIcon from "@mui/icons-material/Telegram";
import logo from "../../img/logo/logo.png"; // with import
import { Link } from "react-router-dom";
import { AppRoute, LocalStorageMethod, UserRole } from "../../enums/enum";
import { useAppDispatch, useAppSelector } from "../../Hooks";
import { useEffect } from "react";
import { getMenuState } from "../../store/main-process/selector";
import { showMenu } from "../../store/main-process/main-process";
import { getUserInfo } from "../../store/user-process/selector";
import LocalStorageProvider from "../../utils/locaStorageProvider";
import LicDialog from "../dialog/lic/LicDialog";
import ChangeLick from "../dialog/lic/changeLic";
import { GetFullInfoByLic, GetPeriodStatus, GetUserLicAddresses } from "../../services/api-actions/integration-action";
import ElectricMeterIcon from "@mui/icons-material/ElectricMeter";
import CalculateIcon from "@mui/icons-material/Calculate";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import InvertColorsIcon from "@mui/icons-material/InvertColors";
import LogoutIcon from "@mui/icons-material/Logout";
import { Stack } from "@mui/material";
import authService from "../api-authorization/AuthorizeService";
import useWindowDimensions from "../../Hooks/windowDimensions";
import { getPersonalInfo } from "../../store/integration-process/selector";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import TopicIcon from "@mui/icons-material/Topic";
import HelpIcon from "@mui/icons-material/Help";
import { userIsRole } from "../../utils/user-is-role";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
//import { GetFullInfoByLic } from '../../services/api-actions/integration-action';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("xs")]: {
    width: `0`,
  },
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  backgroundColor: "#fff !important",
  color: "#c8611d",
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Header() {
  const userInfo = useAppSelector(getUserInfo);
  const colWidth = { xs: 12, sm: 12, md: 6, lg: 6 } as const;
  let defaultLic = LocalStorageProvider(userInfo.userId, null, LocalStorageMethod.getItem);
  if (userInfo.lics?.length >= 1 && defaultLic == "") {
    LocalStorageProvider(userInfo.userId, { lic: userInfo.lics[0].lic, address: "" }, LocalStorageMethod.setItem);
  }
  if (userInfo.lics?.length === 0) {
    LocalStorageProvider(userInfo.userId, null, LocalStorageMethod.removeItem);
    defaultLic = "";
  }
  if (defaultLic != "" && userInfo != undefined && userInfo?.lics.find((x) => x.lic == defaultLic.lic) == undefined) {
    defaultLic = "";
  }
  const { height, width } = useWindowDimensions();
  const AddressFontSize = width > 800 ? "16px" : "11px";
  const [openChangeLick, setOpenCHangeLic] = React.useState(false);

  const dispath = useAppDispatch();
  const open = useAppSelector(getMenuState);
  const theme = useTheme();
  useEffect(() => {
    if (defaultLic != "") {
      dispath(GetFullInfoByLic({ fullLic: defaultLic.lic }));
      dispath(GetUserLicAddresses());
      dispath(GetPeriodStatus());
    }
  }, [defaultLic.lic]);
  const address = useAppSelector(getPersonalInfo)?.address;
  const handleDrawerOpen = () => {
    dispath(showMenu(true));
  };
  const appBarRef = React.useRef(null);
  const handeClick = (event: any) => {
    if (event.target.nodeName != "path" || event.target.nodeName != "svg") {
      dispath(showMenu(false));
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handeClick);
    return () => document.removeEventListener("mousedown", handeClick);
  });
  const handleDrawerClose = () => {
    dispath(showMenu(false));
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" ref={appBarRef} open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            {width > 800 && (
              <Link style={{ textDecoration: "none", backgroundColor: "none", color: "#c8611d" }} to={AppRoute.Main}>
                <img src={logo}></img>
              </Link>
            )}
          </Typography>
          <Typography align="center" variant="body1" component="div" sx={{ flexGrow: 6 }}>
            {defaultLic != "" && defaultLic.lic != "" && (
              <>
                <button
                  style={{ background: "none", color: "inherit", outline: "inherit", border: "none", cursor: "pointer" }}
                  onClick={() => {
                    setOpenCHangeLic(true);
                  }}
                >
                  <Typography variant="subtitle1" style={{ marginBottom: 0 }} gutterBottom>
                    Л.С {defaultLic.lic}
                    <ArrowDropDownIcon />
                  </Typography>
                  <Typography variant="subtitle1" style={{ fontSize: AddressFontSize }} gutterBottom>
                    {address?.fullAddress}
                  </Typography>
                </button>
              </>
            )}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "flex", md: "flex" }, flexGrow: 0 }}>
            <IconButton
              size="small"
              edge="end"
              aria-label="account of current user"
              aria-haspopup="true"
              color="inherit"
              onClick={(e: any) => {
                setOpenCHangeLic(true);
              }}
            >
              {/*<Link style={{textDecoration:'none', backgroundColor: 'none', color: '#c8611d', fontSize: '20px' }} to={AppRoute.Lic} >Л.С {Lic?.lic}</Link>*/}
            </IconButton>
          </Box>
          <LicDialog Open={openChangeLick} Close={setOpenCHangeLic} Title="Изменить лицевой счет" Component={<ChangeLick />} />
          <Box sx={{ display: { xs: "flex", md: "flex" } }}>
            <button
              style={{ background: "none", color: "inherit", outline: "inherit", border: "none", cursor: "pointer" }}
              onClick={() => {
                localStorage.removeItem("token");
                authService.signOut();
              }}
            >
              <Stack alignItems="center" direction="row" gap={2}>
                {width > 800 && <Typography variant="body1">Выйти</Typography>}
                <LogoutIcon />
              </Stack>
            </button>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" variant="permanent" open={open} onMouseOver={handleDrawerOpen} onMouseOut={handleDrawerClose}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>{theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}</IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <Link style={{ textDecoration: "none", backgroundColor: "none", color: "#000000DE" }} to={AppRoute.Main} onClick={handleDrawerClose}>
            <ListItem key={"Главная"} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <AccountBalanceIcon />
                </ListItemIcon>
                <ListItemText primary={"Главная"} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          </Link>
          <Link style={{ textDecoration: "none", backgroundColor: "none", color: "#000000DE" }} to={AppRoute.Lic} onClick={handleDrawerClose}>
            <ListItem key={"Мои лицевые счета"} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <HowToRegIcon />
                </ListItemIcon>
                <ListItemText primary={"Мои лицевые счета"} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          </Link>
          {defaultLic != "" && defaultLic.lic != "" && (
            <>
              <Link style={{ textDecoration: "none", backgroundColor: "none", color: "#000000DE" }} to={`${AppRoute.HistoryPayment}`} onClick={handleDrawerClose}>
                <ListItem key={"История оплат"} disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <CalculateIcon />
                    </ListItemIcon>
                    <ListItemText primary={"История оплат"} sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </ListItem>
              </Link>
              <Link style={{ textDecoration: "none", backgroundColor: "none", color: "#000000DE" }} to={`${AppRoute.Counter}`} onClick={handleDrawerClose}>
                <ListItem key={"Приборы учета"} disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <ElectricMeterIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Приборы учета"} sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </ListItem>
              </Link>
              <Link style={{ textDecoration: "none", backgroundColor: "none", color: "#000000DE" }} to={`${AppRoute.SendReadings}`} onClick={handleDrawerClose}>
                <ListItem key={"Передача показаний"} disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <InvertColorsIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Передача показаний"} sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </ListItem>
              </Link>
            </>
          )}
          <Link style={{ textDecoration: "none", backgroundColor: "none", color: "#000000DE" }} to={`${AppRoute.UserRequests}`} onClick={handleDrawerClose}>
            <ListItem key={"Мои обращения"} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <ContactMailIcon />
                </ListItemIcon>
                <ListItemText primary={"Мои обращения"} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          </Link>
          <Link style={{ textDecoration: "none", backgroundColor: "none", color: "#000000DE" }} to={AppRoute.Documents} onClick={handleDrawerClose}>
            <ListItem key={"Документы"} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <TopicIcon />
                </ListItemIcon>
                <ListItemText primary={"Документы"} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          </Link>
          <Divider />
          <Link style={{ textDecoration: "none", backgroundColor: "none", color: "#000000DE" }} to={`${AppRoute.Profile}`} target="_blank" onClick={handleDrawerClose}>
            <ListItem key={"Настройки"} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <ManageAccountsIcon />
                </ListItemIcon>
                <ListItemText primary={"Настройки"} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          </Link>

          {(userIsRole(userInfo, UserRole.Admin) || userIsRole(userInfo, UserRole.Operator)) && (
            <Link style={{ textDecoration: "none", backgroundColor: "none", color: "#000000DE" }} to={`${AppRoute.Admin}`} onClick={handleDrawerClose}>
              <ListItem key={"Админ панель"} disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <SupervisorAccountIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Админ панель"} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            </Link>
          )}
        </List>
        <List style={{ position: "absolute", bottom: "0", width: "100%" }}>
          <a
            style={{ textDecoration: "none", backgroundColor: "none", color: "#000000DE" }}
            onClick={() => {
              handleDrawerClose();
              window.location.href = "./Files/Инструкция.pdf";
            }}
          >
            <ListItem key={"Инструкция"} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <HelpIcon sx={{ color: "red" }} />
                </ListItemIcon>
                <ListItemText primary={"Инструкция"} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          </a>
        </List>
        <Divider />
      </Drawer>
    </Box>
  );
}
