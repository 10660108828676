import {createSlice} from '@reduxjs/toolkit';
import { NameSpace } from '../../enums/enum';
import { IPersonalInfo } from '../../types/type-integration/persona-info';
import { GetFullInfoByLic, GetPayments, GetPeriodStatus, GetReadingHistory, GetUserLicAddresses } from '../../services/api-actions/integration-action';
import { integration } from '../../types/type-integration/integration-type';
import { IReadingHistory } from '../../types/type-integration/reading-history';
import IUserLicAddresses from '../../types/type-integration/userLicAddresses';
import IPayments from '../../types/type-integration/payments-history';

const initialState: integration = {
   personalInfo: {} as IPersonalInfo,
   readingHistory: [] as IReadingHistory[],
   userLicAddresses: [] as IUserLicAddresses[],
   payments:[] as IPayments[],
   periodLockStatus:false,
};

export const dataIntegration = createSlice({
  name: NameSpace.Integration,
  initialState,
  reducers: {
    userInfo: (state,action)=>{
      state.personalInfo = action.payload;
    },
   
  },
  extraReducers(builder) {
    builder
      .addCase(GetFullInfoByLic.fulfilled, (state, action) => {
        state.personalInfo = action.payload;
      })
      .addCase(GetReadingHistory.fulfilled, (state, action) => {
        state.readingHistory = action.payload;
      })
      .addCase(GetUserLicAddresses.fulfilled, (state, action) => {
        state.userLicAddresses = action.payload;
      })
      .addCase(GetPayments.fulfilled, (state, action) => {
        state.payments = action.payload;
      })
      .addCase(GetPeriodStatus.fulfilled, (state, action) => {
        state.periodLockStatus = action.payload;
      })
  }
});

export const {userInfo} = dataIntegration.actions;