export enum AppRoute{
  Main = '/',
  Login = '/login',
  SignUp = '/signUp',
  Profile = '/Identity/Account/Manage',
  Counter = '/counter',
  Lic = '/Lic',
  Documents = '/Documents',
  HistroryIndication = '/histrory-indication',
  HistoryPayment = '/histrory-payment',
  Counters = '/Counters',
  SendReadings = '/SendReadings',
  Admin = '/Admin',
  UserRequests = '/UserRequests',
  UserRequestChat = '/UserRequestChat',
  NotFound = '*',
  Test = '/Test',
  RefreshToken='/Identity/Account/RefreshSession',
}
export enum AuthorizationStatus{
  Auth = 'AUTH',
  NoAuth = 'NO_AUTH',
  Unknown = 'UNKNOWN',
}

export enum APIRoute {
  Comments = '/comments',
  Login = '/login',
  Logout = '/logout',
}

export enum APIRouteUser {
  UserInfo = 'account/GetUserInfo',
  AddFullLicAtAccount = 'account/AddFullLicAtAccount',
  DeleteFullLicAtAccount = 'account/DeleteFullLicAtAccount',
  AddQueueReceipt = 'account/AddQueueReceipt',
  ChangeElectronicReceipts = 'account/ChangeElectronicReceipts',
  SearchUser = 'account/SearchUser',
  GetAllRoles = 'account/GetAllRoles',
  GetUserRoles = 'account/GetUserRoles?UserId={0}',
  AddUserRole = 'account/AddUserRole?UserId={0}&RoleName={1}',
  RemoveUserRole = 'account/RemoveUserRole?UserId={0}&RoleName={1}',
  RemoveUser = 'account/RemoveUser?UserId={0}',
  AddLicForUser = 'account/AddLicForUser',
}
export enum APIRequestUser{
  InaccuracyPu = 'requestUser/InaccuracyPu',
  CreatePersonalUserRequest = 'requestUser/CreatePersonalUserRequest',
  GetUserRequestAll = 'requestUser/GetUserRequestAll',
  GetUserRequestAllOperrator = 'requestUser/GetUserRequestAllOperrator',
  GetUserRequestOperatorFilter = 'requestUser/GetUserRequestOperatorFilter?take={0}&skip={1}&statusId={2}&lic={3}',
  UpdateStatusUserRequestOperator = 'requestUser/UpdateStatusUserRequestOperator?statusId={0}&requsetId={1}',
  TakeOnJob = 'requestUser/TakeOnJob?requestId={0}',
  RemoveRequest = 'requestUser/RemoveRequest?requestId={0}',
  SetGrade = 'requestUser/SetGrade?requestId={0}&grade={1}',
  SwitchJob = 'requestUser/SwitchJob?requestId={0}&userId={1}',
  GetAllUserRequestMessage = 'requestUser/GetAllUserRequestMessage?requestId={0}',
  CreateMessage = 'requestUser/CreateMessage',
  GetFileRequestMessage = 'requestUser/GetFileRequestMessage?messageId={0}',
}
export enum APIDictionary{
  GetAllSubject = 'Dictionary/GetAllSubject',
  GetAllStatus = 'Dictionary/GetAllStatus',
}


export enum APIRouteComment {
  GetWeatherForecast = 'WeatherForecast',
}

export enum APIRouteIntegration {
  GetFullInfoByLic = 'api/v1/PersonalCabinet/GetFullInfoByLic?lic={0}',
  GetReadingHistory = 'api/v1/PersonalCabinet/GetReadingHistory?lic={0}&typePu={1}&from={2}&to={3}',
  GetPayments = 'api/v1/PersonalCabinet/GetPayments?lic={0}',
  WriteIpuReadings = 'api/v1/PersonalCabinet/WriteIpuReadings',
  GetUserLicAddresses = 'api/v1/PersonalCabinet/GetUserLicAddresses',
  GetPeriodStatus = 'api/v1/Misc/GetPeriodStatus',
  GetReadings = 'api/v1/Misc/GetReadings?beginningDate={0}',
}
export enum APIReport {
  GetUserPersonalAccount = 'Report/UserPersonalAccount?start={0}&end={1}',
  GetPersonalUserRequest = 'Report/PersonalUserRequest?start={0}&end={1}',
  GetWorkInOperator = 'Report/WorkInOperator?start={0}&end={1}',
  GetUserInfoReport = 'Report/GetUserInfoReport',
}

export enum NameSpace {
  MainData = 'MainData',
  User = 'USER',
  Comment = "Comment",
  Integration = "Integration"
}
export enum LocalStorageMethod{
  setItem = 'setItem',
  getItem = 'getItem',
  removeItem = 'removeItem',
  clear = 'clear',
}

export enum AlertType{
  success = "success",
  info = 'info',
  warning = 'warning',
  error = 'error'
}

export enum UserRole{
  Admin = 'Admin',
  Operator = 'Operator',
  OperatorAdmin = 'OperatorAdmin'
}

export enum Status{
  InWork = 1,
  Cansel = 2,
  Success = 3,
  Create = 4
}


