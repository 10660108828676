import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useAppSelector } from '../../../Hooks';
import { getShwoAlert } from '../../../store/user-process/selector';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
type SettingModel={
  Open:boolean,
  Close:React.Dispatch<React.SetStateAction<boolean>>,
  Title:string
  Component:React.JSX.Element
}
export default function LicDialog(settingModel:SettingModel) {
  const allert = useAppSelector(getShwoAlert);
  if(allert?.open == true){
    settingModel.Close(false);
  }
  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={(e)=>settingModel.Close(false)}
        aria-labelledby="customized-dialog-title"
        open={settingModel.Open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {settingModel.Title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={(e)=>settingModel.Close(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {settingModel.Component}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={(e)=>{
            settingModel.Close(false);
            
          }}>
            Закрыть
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}