import ReactDOM from "react-dom/client";
import "./index.css";
import { StyledEngineProvider } from "@mui/material/styles";
import Headers from "./Components/header/Header";
import reportWebVitals from "./reportWebVitals";
import Footer from "./Components/footer/Footer";
import { Provider } from "react-redux";
import { store } from "./store";
import App from "./App";
import ShowAlert from "./Components/allert/alert";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { BrowserRouter } from "react-router-dom";
import Loader from "./Components/allert/loader";

const baseUrl = String(document.getElementsByTagName("base")[0].getAttribute("href"));

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <Provider store={store}>
    <BrowserRouter basename={baseUrl}>
      <StyledEngineProvider injectFirst>
        <Headers />
        <App />
        <ShowAlert />
        <Footer />
        <Loader />
      </StyledEngineProvider>
    </BrowserRouter>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
