import { LocalStorageMethod } from "../enums/enum";

export default function LocalStorageProvider(key:string,value:any,operation:LocalStorageMethod){
  switch(operation){
    case LocalStorageMethod.setItem:
      localStorage.setItem(key, JSON.stringify(value));
      break;
    case LocalStorageMethod.getItem:
      const val = localStorage.getItem(key) ?? "";
      if(val === "" || val === 'undefined')
        return "";
      const items = JSON.parse(localStorage.getItem(key) ?? "");
      return items;
    case LocalStorageMethod.clear:
        localStorage.clear();
        break; 
    case LocalStorageMethod.removeItem:
        localStorage.removeItem(key);
        break;  
    default:
      return "";
  }
}