import { Button } from "@mui/joy";
import Stack from "@mui/joy/Stack";
import { useAppDispatch, useAppSelector } from "../../../Hooks";
import { getUserInfo, getUserRequestMessage } from "../../../store/user-process/selector";
import { userIsRole } from "../../../utils/user-is-role";
import { Status, UserRole } from "../../../enums/enum";
import { IUserInfo } from "../../../types/user-info/user-info";
import { OperatorTakeOnJob, UpdateStatusUserRequestOperator } from "../../../services/api-actions/personal-user-request-action";

type MessagesPaneHeaderProps = {
  thema: string;
  userInfo: IUserInfo;
  chatId: string | undefined;
};

export default function MessagesPaneHeader(props: MessagesPaneHeaderProps) {
  const dispatch = useAppDispatch();
  const userMessage = useAppSelector(getUserRequestMessage);
  const userInfo = useAppSelector(getUserInfo);
  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          borderBottom: "1px solid",
          borderColor: "divider",
          backgroundColor: "background.body",
        }}
        py={{ xs: 2, md: 2 }}
        px={{ xs: 1, md: 2 }}
      >
        <Stack direction="row" spacing={{ xs: 1, md: 2 }} alignItems="center">
          <Button
            size="sm"
            color="primary"
            sx={{ alignSelf: "center", borderRadius: "sm" }}
            //endDecorator={<SendRoundedIcon />}
            onClick={() => {
              window.history.back();
              window.close();
            }}
          >
            Вернуться назад
          </Button>
          <div>
            <b>Тема обращения:</b> {props.thema}
          </div>
          {userMessage != undefined && userMessage != null && (
            <>
              <div>
                <b>Статус заявки:</b> {userMessage[0]?.personalUserRequest?.status?.status}
              </div>
              <div>
                <b>Л/С:</b> {userMessage[0]?.personalUserRequest?.lic == "undefined" ? "не привязан" : userMessage[0]?.personalUserRequest?.lic}
              </div>
              {(userIsRole(userInfo, UserRole.Operator) || userIsRole(userInfo, UserRole.OperatorAdmin) || userIsRole(userInfo, UserRole.Admin)) && (
                <div>
                  <b>Email:</b> {userMessage[0]?.user?.email}
                </div>
              )}
            </>
          )}
        </Stack>
      </Stack>
      {(userIsRole(props.userInfo, UserRole.Admin) || userIsRole(props.userInfo, UserRole.Operator)) && (
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{
            borderBottom: "1px solid",
            borderColor: "divider",
            backgroundColor: "background.body",
          }}
          py={{ xs: 2, md: 2 }}
          px={{ xs: 1, md: 2 }}
        >
          <Stack direction="row" spacing={{ xs: 1, md: 2 }} alignItems="center">
            {userMessage[0]?.personalUserRequest?.status?.id != Status.Cansel && userMessage[0]?.personalUserRequest?.status?.id != Status.Success && (
              <Button
                size="sm"
                color="primary"
                sx={{ alignSelf: "center", borderRadius: "sm" }}
                //endDecorator={<SendRoundedIcon />}
                onClick={() => {
                  if (props.chatId != undefined && props.chatId != null) {
                    dispatch(
                      UpdateStatusUserRequestOperator({
                        statusId: Status.Success.toString(),
                        requestId: props.chatId,
                      })
                    ).then(() => {
                      window.location.reload();
                    });
                  }
                }}
              >
                Выполнено
              </Button>
            )}
            {userMessage[0]?.personalUserRequest?.status?.id != Status.Cansel && userMessage[0]?.personalUserRequest?.status?.id != Status.Success && (
              <Button
                size="sm"
                color="danger"
                sx={{ alignSelf: "center", borderRadius: "sm" }}
                //endDecorator={<SendRoundedIcon />}
                onClick={() => {
                  if (props.chatId != undefined && props.chatId != null) {
                    dispatch(
                      UpdateStatusUserRequestOperator({
                        statusId: Status.Cansel.toString(),
                        requestId: props.chatId,
                      })
                    ).then(() => {
                      window.location.reload();
                    });
                  }
                }}
              >
                Отмена
              </Button>
            )}
            {userMessage[0]?.personalUserRequest?.status?.id != Status.InWork && userMessage[0]?.personalUserRequest?.status?.id != Status.Create && (
              <Button
                size="sm"
                color="success"
                sx={{ alignItems: "right", borderRadius: "sm" }}
                //endDecorator={<SendRoundedIcon />}
                onClick={() => {
                  if (props.chatId != undefined && props.chatId != null)
                    dispatch(OperatorTakeOnJob({ requestId: props.chatId })).then(() => {
                      window.location.reload();
                    });
                }}
              >
                Взять заявку в работу
              </Button>
            )}
          </Stack>
        </Stack>
      )}
    </>
  );
}
