import React, { FC, ReactElement, useState } from "react";
import { Backdrop, Badge, BottomNavigation, BottomNavigationAction, Box, Container, CssBaseline, Fab, FormControl, FormControlLabel, FormLabel, Grid, IconButton, Paper, Radio, RadioGroup, SpeedDialAction, SpeedDialIcon, Switch, Typography, styled } from "@mui/material";
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import AccountCircle from '@mui/icons-material/AccountCircle';
import SpeedDial, { SpeedDialProps } from '@mui/material/SpeedDial';
import AddIcon from '@mui/icons-material/Add';
import useWindowDimensions from "../../Hooks/windowDimensions";
import { useNavigate } from "react-router-dom";
import { AppRoute } from "../../enums/enum";
import { useAppDispatch, useAppSelector } from "../../Hooks";
import { getMenuState } from "../../store/main-process/selector";
import { showMenu } from "../../store/main-process/main-process";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import LicDialog from "../dialog/lic/LicDialog";
import QueryReveipt from "../dialog/lic/QueryReceipt";
import AddLic from "../dialog/lic/AddLic";
import ChangeLick from "../dialog/lic/changeLic";
import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';

export default function Footer(): ReactElement  {
  const dispath = useAppDispatch();

  const [openDialogAddLic, setOpenDialogAddLic] = useState(false);
  const [openKvitDialog, setOpenKvitDialog] = useState(false);
  const [openChangeLick, setOpenCHangeLic] = useState(false);

  const open = useAppSelector(getMenuState);
  const { height, width } = useWindowDimensions();
  const navigate = useNavigate();

  const [openMenu, setOpenMenu] = React.useState(false);
  const handleOpen = () => setOpenMenu(true);
  const handleClose = () => setOpenMenu(false);

  return (
    <>
    {width < 800 && (
    <Box >
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation
            showLabels
            onChange={(event, newValue) => {
            }}
        >
            <BottomNavigationAction label="Меню" onClick={()=>{dispath(showMenu(!open))}} icon={<MenuOpenIcon />} />
            <IconButton
              size="large"
              aria-label="show 2 new notifications"
              color="inherit"
            >
            </IconButton>
            {/*<BottomNavigationAction label="Профиль" icon={<AccountCircle />} 
              onClick={()=>{window.open(AppRoute.Profile,"_self")}} />*/}
         
                <PopupState variant="popover" popupId="demo-popup-menu">
                  {(popupState:any) => (
                    <React.Fragment>
                      <Button variant="contained" {...bindTrigger(popupState)} style={{color:'none', backgroundColor:'green'}}>
                        <AddIcon />
                      </Button>
                      <Menu {...bindMenu(popupState)}>
                        <MenuItem onClick={(e:any)=>{
                          popupState.close();
                          window.location.href = AppRoute.SendReadings;;
                          }}>Передать показания</MenuItem>
                        <MenuItem onClick={(e:any)=>{
                          popupState.close();
                          setOpenKvitDialog(true);
                          }}>Архив квитанций</MenuItem>
                        <MenuItem onClick={(e:any)=>{
                          popupState.close();
                          setOpenDialogAddLic(true);
                          }}>Добавить лицевой счет</MenuItem>
                        <MenuItem onClick={(e:any)=>{
                          popupState.close();
                          setOpenCHangeLic(true);
                          }}>Изменить лицевой счет</MenuItem>
                      </Menu>
                    </React.Fragment>
                  )}
                </PopupState>
            
            <BottomNavigationAction label="Главная" icon={ <AccountCircle />} 
              onClick={()=>{navigate(AppRoute.Main)}} />
         <LicDialog Open={openKvitDialog} Close={setOpenKvitDialog} Title='Архив квитанций' Component={<QueryReveipt/>} />
         <AddLic Open={openDialogAddLic} Close={setOpenDialogAddLic} Title='Добавить лицевой счет' />
         <LicDialog Open={openChangeLick} Close={setOpenCHangeLic} Title='Изменить лицевой счет' Component={<ChangeLick/>} />
        </BottomNavigation>
        </Paper>
    </Box>)}
    {width > 800 && (
      <Box>
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
          <Box >
            <Box >
            <Backdrop open={openMenu} />
              <StyledSpeedDial
                ariaLabel="SpeedDial tooltip example"
                sx={{ position: 'absolute', bottom: 16, right: 16 }}
                icon={<SpeedDialIcon />}
                onClose={handleClose}
                onOpen={handleOpen}
                open={openMenu}
              >
                <SpeedDialAction
                  key={1}
                  icon={<CurrencyRubleIcon />}
                  tooltipTitle="Передать показания"
                  
                  onClick={()=>{
                    handleClose();
                    window.location.href = AppRoute.SendReadings;
                    handleClose();
                  }}
                  />
                <SpeedDialAction
                  key={2}
                  icon={<ReceiptLongIcon  />}
                  tooltipTitle="Архив квитанций"
                  
                  onClick={()=>{
                    handleClose();
                    setOpenKvitDialog(true);
                    handleClose();
                  }}
                />

              </StyledSpeedDial>
            </Box>
            <LicDialog Open={openKvitDialog} Close={setOpenKvitDialog} Title='Запрос квитанций' Component={<QueryReveipt/>} />
          </Box>
        </Paper>
      </Box>
    )}
    </>
  );
};
const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
  position: 'absolute',
  '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
    top: theme.spacing(2),
    left: theme.spacing(2),
  },
}));

