import { Box, Button, Card, Chip, Divider, Grid, Stack, Typography } from "@mui/material";
import { AddToHomeScreen } from "react-pwa-add-to-homescreen";
import useWindowDimensions from "../../Hooks/windowDimensions";
import DownloadIcon from '@mui/icons-material/Download';
import TopicIcon from '@mui/icons-material/Topic';

export default function Documents(){
  const { height, width } = useWindowDimensions();
  return(
  <>
     <>
      <div className='padingMedia'>
        <AddToHomeScreen />
        <Box  sx={{ flexGrow: 2,minHeight:'100vh',backgroundColor:'#f5f5f5', pt: width < 595 ? 3 : 10 , pl: width < 595 ? 0 : 10, pr: width < 595 ? 0 : 10  }}>  
          <Typography gutterBottom variant="h4" component="div" align='left'>
            Документы
          </Typography>
          
          <Grid container spacing={2} marginTop={1}>
            <Grid item {...{ xs: 12, sm: 12, md: 12, lg: 12 }} paddingBottom={1} minHeight={0}>
              <Card variant="outlined">
                <Box sx={{ p: 2 }}>
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography gutterBottom variant="h5" component="div">
                      <TopicIcon/> Бланк заявления по ИПУ
                    </Typography>
                    <Typography gutterBottom variant="h5" component="div">
                    <Button variant="text" size="medium"  color="success" endIcon={<DownloadIcon />} onClick={()=>{
                      window.location.href = './Files/Бланк заявки по ИПУ.doc'
                    }} >
                      Загрузить
                    </Button>
                    </Typography>
                  </Stack>
                  {/* <Typography color="text.secondary" variant="body2">
                    описание если надо
                  </Typography> */}
                </Box>
              </Card>
              <Card variant="outlined">
                <Box sx={{ p: 2 }}>
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography gutterBottom variant="h5" component="div">
                      <TopicIcon/> Согласие на обработку персональных данных
                    </Typography>
                    <Typography gutterBottom variant="h5" component="div">
                    <Button variant="text" size="medium"  color="success" endIcon={<DownloadIcon />} onClick={()=>{
                      window.location.href = './Files/Согласие на обработку персональных данных.docx'
                    }} >
                      Загрузить
                    </Button>
                    </Typography>
                  </Stack>
                  {/* <Typography color="text.secondary" variant="body2">
                    описание если надо
                  </Typography> */}
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Box>  
      </div>  
    </>
  </>
  );
}
