import * as React from "react";
import Avatar from "@mui/joy/Avatar";
import Box from "@mui/joy/Box";
import Stack from "@mui/joy/Stack";
import Sheet from "@mui/joy/Sheet";
import Typography from "@mui/joy/Typography";
import InsertDriveFileRoundedIcon from "@mui/icons-material/InsertDriveFileRounded";
import { IUserMessage } from "../../../types/user-info/user-message";
import dayjs from "dayjs";
import { useAppDispatch } from "../../../Hooks";
import { GetFileRequestMessage } from "../../../services/api-actions/personal-user-request-action";

type ChatBubbleProps = IUserMessage & {
  variant: "sent" | "received";
  sender: string;
};

export default function ChatBubble(props: ChatBubbleProps) {
  const dispatch = useAppDispatch();
  const { message, variant, createdDate, fileName, sender, id } = props;
  const isSent = variant === "sent";
  const [isHovered, setIsHovered] = React.useState<boolean>(false);
  return (
    <Box sx={{ maxWidth: "60%", minWidth: "auto" }}>
      <Stack direction="row" justifyContent="space-between" spacing={2} sx={{ mb: 0.25 }}>
        <Typography level="body-xs">{sender}</Typography>
        <Typography level="body-xs">{dayjs(createdDate)?.format("YYYY-MM-DD HH:mm:ss")}</Typography>
      </Stack>

      <Box sx={{ position: "relative" }} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
        <Sheet
          color={isSent ? "primary" : "neutral"}
          variant={isSent ? "solid" : "soft"}
          sx={{
            p: 1.25,
            borderRadius: "lg",
            borderTopRightRadius: isSent ? 0 : "lg",
            borderTopLeftRadius: isSent ? "lg" : 0,
            backgroundColor: isSent ? "var(--joy-palette-primary-solidBg)" : "background.body",
          }}
        >
          <Typography
            level="body-sm"
            sx={{
              color: isSent ? "var(--joy-palette-common-white)" : "var(--joy-palette-text-primary)",
            }}
          >
            {message}
          </Typography>
        </Sheet>
        {fileName != null && (
          <Sheet
            variant="outlined"
            sx={{
              px: 1.75,
              py: 1.25,
              borderRadius: "lg",
              borderTopRightRadius: isSent ? 0 : "lg",
              borderTopLeftRadius: isSent ? "lg" : 0,
            }}
          >
            <Stack direction="row" spacing={1.5} alignItems="center">
              <div
                onClick={() => {
                  //Скачать файл
                  dispatch(GetFileRequestMessage({ messageId: id, fileName: fileName }));
                }}
              >
                <Avatar color="primary" size="lg">
                  <InsertDriveFileRoundedIcon />
                </Avatar>
              </div>
              <div>
                <Typography fontSize="sm">{fileName}</Typography>
                {/* <Typography level="body-sm">{attachment.size}</Typography>  */}
              </div>
            </Stack>
          </Sheet>
        )}
      </Box>
    </Box>
  );
}
