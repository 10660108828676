import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Button, Grid, TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../Hooks";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";
import dayjs from "dayjs";
import { GetReadings } from "../../../services/api-actions/integration-action";
import { userIsRole } from "../../../utils/user-is-role";
import { UserRole } from "../../../enums/enum";
import { getUserInfo } from "../../../store/user-process/selector";
import { PersonalUserRequest, UserInfoReport, UserPersonalAccount, WorkInOperator } from "../../../services/api-actions/report-action";

export default function Reports() {
  const colWidth = { xs: 12, sm: 12, md: 6, lg: 6 } as const;

  const dispatch = useAppDispatch();
  const userInfo = useAppSelector(getUserInfo);
  const [selectReadingDate, setSelectReadingDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  return (
    <>
      {userIsRole(userInfo, UserRole.Admin) && (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
            Отчет по показаниям
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              <Grid item {...colWidth}>
                <TextField
                  id="outlined-number"
                  label=""
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ width: "100%" }}
                  helperText={`Выберите дату`}
                  onChange={(e: any) => {
                    setSelectReadingDate(dayjs(e.target.value).format("YYYY-MM-DD"));
                  }}
                />
              </Grid>
              <Grid style={{ textAlign: "center" }} item {...colWidth}>
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => {
                    dispatch(GetReadings({ BeginningDate: selectReadingDate }));
                  }}
                >
                  Скачать
                </Button>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      )}
      {userIsRole(userInfo, UserRole.Admin) && (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
            Отчет пользователи ЛК
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              <Grid style={{ textAlign: "center" }} item {...colWidth}>
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => {
                    dispatch(UserInfoReport());
                  }}
                >
                  Скачать
                </Button>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      )}
      {(userIsRole(userInfo, UserRole.OperatorAdmin) || userIsRole(userInfo, UserRole.Admin)) && (
        <>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
              Пользователи ЛК
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid item {...{ xs: 12, sm: 12, md: 4, lg: 4 }}>
                  <TextField
                    id="outlined-number"
                    label=""
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{ width: "100%" }}
                    helperText={`Начальная дата`}
                    onChange={(e: any) => {
                      setStartDate(dayjs(e.target.value).format("YYYY-MM-DD"));
                    }}
                  />
                </Grid>
                <Grid item {...{ xs: 12, sm: 12, md: 4, lg: 4 }}>
                  <TextField
                    id="outlined-number"
                    label=""
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{ width: "100%" }}
                    helperText={`Конечная дата`}
                    onChange={(e: any) => {
                      setEndDate(dayjs(e.target.value).format("YYYY-MM-DD"));
                    }}
                  />
                </Grid>
                <Grid style={{ textAlign: "center" }} item {...{ xs: 12, sm: 12, md: 4, lg: 4 }}>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() => {
                      dispatch(
                        UserPersonalAccount({
                          startDate: startDate,
                          endDate: endDate,
                        })
                      );
                    }}
                  >
                    Скачать
                  </Button>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
              Обращения
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid item {...{ xs: 12, sm: 12, md: 4, lg: 4 }}>
                  <TextField
                    id="outlined-number"
                    label=""
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{ width: "100%" }}
                    helperText={`Начальная дата`}
                    onChange={(e: any) => {
                      setStartDate(dayjs(e.target.value).format("YYYY-MM-DD"));
                    }}
                  />
                </Grid>
                <Grid item {...{ xs: 12, sm: 12, md: 4, lg: 4 }}>
                  <TextField
                    id="outlined-number"
                    label=""
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{ width: "100%" }}
                    helperText={`Конечная дата`}
                    onChange={(e: any) => {
                      setEndDate(dayjs(e.target.value).format("YYYY-MM-DD"));
                    }}
                  />
                </Grid>
                <Grid style={{ textAlign: "center" }} item {...{ xs: 12, sm: 12, md: 4, lg: 4 }}>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() => {
                      dispatch(
                        PersonalUserRequest({
                          startDate: startDate,
                          endDate: endDate,
                        })
                      );
                    }}
                  >
                    Скачать
                  </Button>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
              Работа операторов
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid item {...{ xs: 12, sm: 12, md: 4, lg: 4 }}>
                  <TextField
                    id="outlined-number"
                    label=""
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{ width: "100%" }}
                    helperText={`Начальная дата`}
                    onChange={(e: any) => {
                      setStartDate(dayjs(e.target.value).format("YYYY-MM-DD"));
                    }}
                  />
                </Grid>
                <Grid item {...{ xs: 12, sm: 12, md: 4, lg: 4 }}>
                  <TextField
                    id="outlined-number"
                    label=""
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{ width: "100%" }}
                    helperText={`Конечная дата`}
                    onChange={(e: any) => {
                      setEndDate(dayjs(e.target.value).format("YYYY-MM-DD"));
                    }}
                  />
                </Grid>
                <Grid style={{ textAlign: "center" }} item {...{ xs: 12, sm: 12, md: 4, lg: 4 }}>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() => {
                      dispatch(
                        WorkInOperator({
                          startDate: startDate,
                          endDate: endDate,
                        })
                      );
                    }}
                  >
                    Скачать
                  </Button>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </>
      )}
    </>
  );
}
