import { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import authService from "../../Components/api-authorization/AuthorizeService";
import { Api } from "../../store";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppDispatch, State } from "../../types/state";
import { APIDictionary, AppRoute } from "../../enums/enum";
import IPersonalUserRequestSubject from "../../types/dictionary/PersonalUserRequestSubject";
import IPersonalUserRequestStatus from "../../types/dictionary/PersonalUserRequestStatus";


interface AdaptAxiosRequestConfig extends AxiosRequestConfig {
  headers: AxiosRequestHeaders
}

async function AddBearerToken(Services:Api){
  const user = await authService.getUser();
  const token = await authService.getAccessToken();
  var seconds = Math.floor(Date.now() / 1000);
  if(seconds > user.auth_time + 21600){
    window.location.href = AppRoute.RefreshToken;
  }
  Services.Integrations.interceptors.request.use(
    (config: AdaptAxiosRequestConfig) => {
      config.headers['Authorization'] = `Bearer ${token}`;
      return config;
    }
  );
}

export const GetAllSubject = createAsyncThunk<IPersonalUserRequestSubject[], undefined, {
  dispatch: AppDispatch,
  state: State,
  extra: Api
}>(
  'data/GetAllSubject',
  async (_arg, {extra: Services }) => {

      await AddBearerToken(Services);
      const {data} =  await Services.Identity.get(APIDictionary.GetAllSubject);
      return data;
  },
);
export const GetAllStatus = createAsyncThunk<IPersonalUserRequestStatus[], undefined, {
  dispatch: AppDispatch,
  state: State,
  extra: Api
}>(
  'data/GetAllStatus',
  async (_arg, {extra: Services }) => {

      await AddBearerToken(Services);
      const {data} =  await Services.Identity.get(APIDictionary.GetAllStatus);
      return data;
  },
);
