import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { Alert, AlertTitle, Button, Container, Grid, ThemeProvider, Toolbar, Typography, createTheme } from '@mui/material';
import useWindowDimensions from '../../Hooks/windowDimensions';
import { userIsRole } from '../../utils/user-is-role';
import { Status, UserRole } from '../../enums/enum';
import { useAppDispatch, useAppSelector } from '../../Hooks';
import { getUserInfo, getUserRequestAll } from '../../store/user-process/selector';
import UserRequestCreate from '../../Components/dialog/user-request-create/user-request-create';
import { GetAllSubject } from '../../services/api-actions/dictionaty-action';
import IPersonalUserRequestSubject from '../../types/dictionary/PersonalUserRequestSubject';
import { GetUserRequestAll } from '../../services/api-actions/personal-user-request-action';
import { UserRequest } from '../../types/user-info/user-request';
import { Link } from 'react-router-dom';

const defaultTheme = createTheme();

export default function UserRequests(){

  const dispatch = useAppDispatch();
  const colWidth = { xs: 12, sm: 6, md: 6, lg: 6 } as const;
  const { height, width } = useWindowDimensions();
  const [openDialog, setOpenDialog] = React.useState(false);
  //const [userRequest,setUserRequest] = React.useState<UserRequest[] | undefined>();

  React.useEffect(()=>{
    dispatch(GetUserRequestAll()).then((res)=>{
      //setUserRequest(res.payload as UserRequest[]);
    });
  },[dispatch])

  const userRequest = useAppSelector(getUserRequestAll);

  return(
    <>
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }} >
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }} style={ width >= 600 ? { paddingLeft:'70px'} : { paddingLeft:'12px'} }>
            <Grid container spacing={0}>
              <Grid item {...{ xs: 12, sm: 8, md: 8, lg: 8 }} >
                <Grid container spacing={0}>
                  <Grid item {...{ xs: 12, sm: 8, md: 8, lg: 8 }} >
                    <Typography variant='h4' style={{textAlign:'center', fontWeight:500}} >Мои обращения</Typography>
                  </Grid>
                  <Grid item {...{ xs: 12, sm: 4, md: 4, lg: 4 }} >
                    <Button variant="contained" color="success" onClick={()=>{
                      setOpenDialog(true);
                    }}>
                        Создать обращение
                    </Button>
                  </Grid>
                </Grid>
                {userRequest?.filter(x=>x.status.id == Status.Create || x.status.id == Status.InWork).map((item,index)=>{
                  return(
                    <Link to={`../UserRequestChat/${item.requestId}`} style={{textDecoration:'none'}} > 
                      <Alert variant='filled' style={{marginRight:'20px',marginLeft:'20px', marginTop:'10px'}}
                      severity={Getseverity(item.status.id)}
                      >
                        <AlertTitle>Статус заявки: {item.status.status}</AlertTitle>
                        Тема: {item.subject.subject}
                      </Alert>
                     </Link>
                     );
                })}
              </Grid>
              <Grid item {...{ xs: 12, sm: 4, md: 4, lg: 4 }} >
                <div style={{padding:'10px', border:'1px solid #e1e1e1'}} >
                  <Typography variant='h6' style={{textAlign:'center', fontWeight:500}} >Когда ждать ответ</Typography>
                  <Typography variant='body1' style={{textAlign:'left'}} >Мы постараемся ответить Вам в кратчайшие сроки.
                    <br/>
                    <br/>
                    Если вы забыли рассказать что-то важное, просто оставьте комментарий на странице обращения — мы увидим его.
                    <br/>
                    <br/>
                    Пожалуйста, не отправляйте сообщение повторно и не перезванивайте с тем же вопросом в колл-центр — поток новых обращений увеличит время ответа.
                  </Typography>
                </div>
                
              </Grid>
            </Grid>
            <UserRequestCreate Open={openDialog} Close={setOpenDialog} />
          </Container>
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }} style={ width >= 600 ? { paddingLeft:'70px'} : { paddingLeft:'12px'} }>
            <Grid container spacing={0}>
              <Grid item {...{ xs: 12, sm: 12, md: 12, lg: 12 }} >
                {userRequest?.find(x=>x.status.id == Status.Cansel || x.status.id == Status.Success) != undefined &&
                  <Typography variant='h4' style={{textAlign:'center', fontWeight:500}} >История обращений</Typography>
                }
                {userRequest?.filter(x=>x.status.id == Status.Cansel || x.status.id == Status.Success).map((item,index)=>{
                  return(
                    <Link to={`../UserRequestChat/${item.requestId}`} style={{textDecoration:'none'}} >   
                      <Alert variant='filled' style={{marginRight:'20px',marginLeft:'20px', marginTop:'10px'}}
                      severity={Getseverity(item.status.id)}
                      >
                        <AlertTitle>Статус заявки: {item.status.status}</AlertTitle>
                        {item.subject.subject}
                      </Alert>
                     </Link>
                    );
                })}
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  </>
  );
}
function Getseverity(id:number){
  if(id === 0 || id === 1)
    return 'info';
  if(id === 2)
    return 'error';
  if(id === 3)
    return 'success';
  if(id === 4)
    return 'warning';

  return 'info';
}

