import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import useWindowDimensions from "../../Hooks/windowDimensions";
import { getPersonalInfo, getReadingHistory } from "../../store/integration-process/selector";
import { useAppSelector } from "../../Hooks";
import { Button, CardActions, CardContent, Grid, Paper, styled, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useState } from "react";
import IndicateAnInaccuracy from "../../Components/dialog/counter/indicate-an-inaccuracy";
import { getUserInfo } from "../../store/user-process/selector";

const Div = styled("div")(({ theme }) => ({
  ...theme.typography.button,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1),
  textAlign: "center",
}));

const defaultTheme = createTheme();

export default function Counters() {
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogData, setDialogData] = useState({
    TypePu: "",
    NumberPu: "",
  });
  const colWidth = { xs: 12, sm: 12, md: 4, lg: 4 } as const;
  const { height, width } = useWindowDimensions();
  const counters = useAppSelector(getPersonalInfo)?.counters;

  return (
    <>
      <ThemeProvider theme={defaultTheme}>
        <Box sx={{ display: "flex" }}>
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) => (theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[900]),
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Toolbar />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }} style={width >= 600 ? { paddingLeft: "70px" } : { paddingLeft: "12px" }}>
              {counters?.length > 0 && (
                <>
                  <Div>{"Здесь отображены все ваши приборы учета"}</Div>
                </>
              )}
              <Grid container spacing={2}>
                {counters?.length > 0 &&
                  counters.map((item, index) => (
                    <Grid item {...colWidth}>
                      <Box>
                        <Paper elevation={3}>
                          <CardContent>
                            <Typography variant="h5" component="div">
                              {item.typePu}
                            </Typography>
                            <Typography color="text.secondary">Номер прибора учета: {item.factoryNumber}</Typography>
                            <Typography color="text.secondary">Модель: {item.model}</Typography>
                            <Typography color="text.secondary">Бренд: {item.brand}</Typography>
                            <Typography color="text.secondary">Дата поверки: {item.dateCheck != null ? dayjs(item.dateCheck)?.format("DD.MM.YYYY") : "Не указано"}</Typography>
                            <Typography color="text.secondary">
                              Дата следующей поверки: {item.dateCheckNext != null ? dayjs(item.dateCheckNext)?.format("DD.MM.YYYY") : "Не указано"}
                            </Typography>
                            <Typography color="text.secondary">Интервал поверки: {item.interVerificationInterval} лет</Typography>
                            <Typography color="text.secondary">Единица измерения: {item.dimension}</Typography>
                            <Typography color="text.secondary">
                              Дата акта ввода: {item.installationDate != null ? dayjs(item.installationDate)?.format("DD.MM.YYYY") : "Не указано"}
                            </Typography>
                            <Typography color="text.secondary">Последние показания: {item.lastReadings}</Typography>
                            <Typography color="text.secondary">
                              Дата последнего показания: {item.lastReadingDate != null ? dayjs(item.lastReadingDate)?.format("DD.MM.YYYY") : "Не указано"}
                            </Typography>
                          </CardContent>
                          <CardActions>
                            {/*  <Button 
                      size="small"
                      onClick={()=>{
                        setOpenDialog(true);
                        setDialogData({ NumberPu: item.factoryNumber , TypePu: item.typePu})
                      }}
                    > */}
                            {GetStatus(item.dateCheckNext)}
                            {/*  </Button> */}
                          </CardActions>
                        </Paper>
                      </Box>
                    </Grid>
                  ))}
              </Grid>
              {counters == null || (counters?.length == 0 && <Div>{"Мы не смогли найти у вас приборы учета."}</Div>)}
            </Container>
            <div style={width <= 800 ? { marginBottom: "40px", display: "block" } : {}}></div>
          </Box>
        </Box>
        <IndicateAnInaccuracy Open={openDialog} Close={setOpenDialog} NumberPu={dialogData.NumberPu} TypePu={dialogData.TypePu} />
      </ThemeProvider>
    </>
  );
}
function GetStatus(date: string | undefined) {
  if (date == undefined) return "";
  var dateCheckNext = dayjs(date);
  var dates = dayjs();
  var result = dates.diff(dateCheckNext, "month") * -1;
  if (result >= 4) {
    return <Typography color="green">Статус: Поверен</Typography>;
  }
  if (result >= 2) {
    return <Typography color="orange">Статус: Подходит срок поверки</Typography>;
  }
  if (result <= 0) {
    return <Typography color="red">Статус: Закончился срок поверки</Typography>;
  }
}
