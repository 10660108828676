import { createSlice } from '@reduxjs/toolkit';
import { NameSpace } from '../../enums/enum';

export type dataMainType = {
    openMenu:boolean;
    loading:boolean;
}

const initialState : dataMainType = {
    openMenu: false,
    loading:false,
};

export const dataMain = createSlice({
  name: NameSpace.MainData,
  initialState,
  reducers: {
    showMenu: (state,action)=>{
      state.openMenu = action.payload;
    },
    setLoading: (state,action)=>{
      state.loading = action.payload;
    },
  },
});

export const {showMenu,setLoading} = dataMain.actions;
