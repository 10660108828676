import { Button, Card, CardActions,  CardContent, CardMedia, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Typography, styled } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import React, { useState } from "react";
import ApiAuthorzationRoutes from '../../api-authorization/ApiAuthorizationRoutes';
import { useAppDispatch, useAppSelector } from "../../../Hooks";
import { AddFullLicAtAccount, GetUserInfo } from "../../../services/api-actions/user-action";
import { getUserInfo } from "../../../store/user-process/selector";
import useWindowDimensions from "../../../Hooks/windowDimensions";
import AuthorizeRoute from "../../api-authorization/AuthorizeRoute";
import Main from "../../../Page/Main/Main";
import ShowAlert from "../../allert/alert";
import { Link } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import { AppRoute } from "../../../enums/enum";
import authService from '../../api-authorization/AuthorizeService';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
type SettingModel={
  Open:boolean,
  Close:React.Dispatch<React.SetStateAction<boolean>>,
  Title:string
}

export default function AddLic(settingModel:SettingModel){
  const { height, width } = useWindowDimensions();
  let MinWidth = width < 800 ? '300px' : '230px';
  const [addLicForUser,setAddLicForUser] = useState({
    fullLic: '',
    indicationDate: new Date("1970-01-01"),
    indicationValue: 0,
  });
  const userInfo = useAppSelector(getUserInfo);
  const dispatch = useAppDispatch();
 // React.useEffect(() => {
   // localStorage.setItem('token', JSON.stringify(accessToken));
 //   dispatch(GetUserInfo());
 // }, [dispatch]);
  return(
  <>
   <React.Fragment>
      <BootstrapDialog
        onClose={(e)=>settingModel.Close(false)}
        aria-labelledby="customized-dialog-title"
        open={settingModel.Open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {settingModel.Title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={(e)=>settingModel.Close(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Card className=''>
            <CardMedia style={{padding: '10px'}}>
            <p>Введите лицевой счет и сумму последнего платежа</p>
            <div className=''>
              <TextField
                required
                value={addLicForUser.fullLic}
                style={{marginTop:"20px"}}
                id="FullLic"
                label="Лицевой счет"
                type="Лицевой счет"
                sx={{width:{ xs: 200, sm: 200, md: 200, lg: 200 }}}
                onInput={(e:any)=>{
                  setAddLicForUser({fullLic:e.target.value,indicationDate:addLicForUser.indicationDate, indicationValue: addLicForUser.indicationValue});
                }}
                autoComplete="current-password"
              />
              <TextField
                style={{marginTop:"20px"}}
                required
                //value={addLicForUser.indicationValue}
                InputLabelProps={{ shrink: true }}
                id="IndicationValue"
                label="Сумма последнего платежа"
                sx={{width:{ xs: 200, sm: 200, md: 200, lg: 200 }}}
                type="number"
                onInput={(e:any)=>{
                  setAddLicForUser({fullLic:addLicForUser.fullLic,indicationDate:addLicForUser.indicationDate, indicationValue: e.target.value});
                }}
              />
            </div>
            </CardMedia>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  <Button variant="contained" color="success" endIcon={<SendIcon />}
                    onClick={()=>{
                      dispatch(AddFullLicAtAccount({userId:userInfo.userId,
                        fullLic:addLicForUser.fullLic,
                        IndicationValue:addLicForUser.indicationValue, 
                        IndicationDate : addLicForUser.indicationDate}));
                        if(addLicForUser.fullLic.length > 0 && (addLicForUser.indicationValue != 0 || addLicForUser.indicationDate == new Date('1970-01-01')))
                          setAddLicForUser({fullLic:'',indicationDate:new Date("1970-01-01"), indicationValue:0});
                        settingModel.Close(false);
                    }}
                  >
                    Добавить
                  </Button>
                </Typography>
              </CardContent>
            <CardActions>
            </CardActions>
          </Card>
      </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={(e)=>settingModel.Close(false)}>
            Закрыть
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  </>
  );
}
function toJSONLocal(date:Date) {
  var local = new Date(date);
  local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  return local.toJSON().slice(0, 10);
}
