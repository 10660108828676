import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from '../../title/title';
import { IReadingHistory } from '../../../types/type-integration/reading-history';
import dayjs from 'dayjs';
import IPayments from '../../../types/type-integration/payments-history';

// Generate Order Data

type DataValue = {
  data: IPayments[]
}

export default function HistoryPaymentTable(readingHistory:DataValue) {
  return (
    <React.Fragment>
      <Title>История оплат</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Период платежа</TableCell>
            <TableCell>Дата платежа</TableCell>
            <TableCell>Сумма платежа</TableCell>
            <TableCell>Пункт оплаты</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {readingHistory.data.map((row) => (
            <TableRow key={row.transactionAmount}>
               <TableCell>{dayjs(row.period).format('MM.YYYY')}</TableCell>
              <TableCell>{dayjs(row.paymentDate).format('DD.MM.YYYY HH:MM:ss')}</TableCell>
              <TableCell>{row.transactionAmount}</TableCell>
              <TableCell>{row.organization}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {/* <Link color="primary" href="#" onClick={preventDefault} sx={{ mt: 3 }}>
        See more orders
      </Link> */}
    </React.Fragment>
  );
}
