import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { Alert, AlertTitle, Autocomplete, Button, Checkbox, Container, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Pagination, Select, Stack, TextField, ThemeProvider, Toolbar, Typography, createTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../Hooks';
import { GetUserRequestAllOperrator, GetUserRequestOperatorFilter, OperatorTakeOnJob, RemoveRequest, SwitchJob } from '../../../services/api-actions/personal-user-request-action';
import useWindowDimensions from '../../../Hooks/windowDimensions';
import { getSearchUserByFio, getUserInfo, getUserRequestAll } from '../../../store/user-process/selector';
import { Status, UserRole } from '../../../enums/enum';
import { GetAllStatus } from '../../../services/api-actions/dictionaty-action';
import IPersonalUserRequestStatus from '../../../types/dictionary/PersonalUserRequestStatus';
import dayjs from 'dayjs';
import { userIsRole } from '../../../utils/user-is-role';
import { SearchUserFio } from '../../../services/api-actions/user-action';

const defaultTheme = createTheme();
const pageSizeInfo = 5;
export default function OperatorProcessingRequests(){

  const dispatch = useAppDispatch();
  const { height, width } = useWindowDimensions();
  const [status,setStatus] = React.useState<IPersonalUserRequestStatus[]>([]);
  const [selectStatusId,setSelectStatusId] = React.useState<number>(0);
  const [selectLic,setSelectLic] = React.useState<string>('');
  const [selectOperator,setOperator] = React.useState<string>('');
  const [switchJob,setSwitchJob] = React.useState<boolean>(false);
  const [selectPage,setSelectPage] = React.useState<number>(0);
  const [selectPageTop,setSelectPageTop] = React.useState<number>(0);
  const userRequest = useAppSelector(getUserRequestAll);
  const userInfo = useAppSelector(getUserInfo);

  const searchUser = useAppSelector(getSearchUserByFio)?.map((item)=> { 
    return { label: `${item.fio} ${item.email}` , id: item.userId };
   });
   const [searchValue,setSearchValue] = React.useState("");
   const [selectUserInfo,setSelectUserInfo] = React.useState({
    userId:'',
    lic:'',
    fio:''
  });
  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch(SearchUserFio({fio:searchValue}));
    }, 2000);
    return () => clearTimeout(timeoutId);
  }, [searchValue]);

  React.useEffect(()=>{
    dispatch(GetAllStatus()).then((result:any)=>{
      setStatus(result?.payload as IPersonalUserRequestStatus[]);
      setStatus(prevState => [...prevState,{id:0,status:'Мои заявки в работе'} as IPersonalUserRequestStatus ])
     });
  },[dispatch]);
  React.useEffect(()=>{
    const timeoutId = setTimeout(() => {
      switch(selectStatusId){
        case 0 :
          dispatch(GetUserRequestAllOperrator());
          break;
        default: 
          dispatch(GetUserRequestOperatorFilter({take:pageSizeInfo.toString(),skip:(selectPage * pageSizeInfo).toString(),statusId:selectStatusId.toString(),lic:selectLic}));
          break;
      }
    }, 1500);
    return () => clearTimeout(timeoutId);
  },[selectStatusId,selectPage,selectLic,selectOperator]);

  return(
    <>
      <Container maxWidth="lg" sx={{ mt: 0, mb: 0 }} style={ width >= 600 ? { paddingLeft:'70px'} : { paddingLeft:'12px'} }>
        <Grid container spacing={0}>
          <Grid item {...{ xs: 12, sm: 9, md: 9, lg: 9 }} >
            <Typography variant='body1' style={{textAlign:'center', fontWeight:500}} >{status?.filter((x)=>x.id == selectStatusId)[0]?.status}</Typography>
            {(userRequest != undefined && userRequest != null && userRequest[0]?.count > pageSizeInfo) && 
              <Stack style={{margin:'auto',width:'50%'}} spacing={2}>
                <Pagination count={Math.ceil(userRequest[0]?.count / pageSizeInfo)} color="primary" page={selectPageTop+1} onChange={(event, pageNumber)=>{
                  setSelectPage(pageNumber-1);
                  setSelectPageTop(pageNumber-1);
                }} />
              </Stack>
            }
            {userRequest?.map((item,index)=>{
              return(
                <>
                <Link to={`../UserRequestChat/${item.requestId}`} style={{textDecoration:'none'}} target='_blank' > 
                  <Alert variant='filled' style={{marginRight:'20px',marginLeft:'20px', marginTop:'10px'}}
                  severity={Getseverity(item.status.id)}
                  >
                    <AlertTitle>Статус заявки: {item.status.status}</AlertTitle>
                    Тема: {item.subject.subject}
                    <AlertTitle>Обращение: {item.message}</AlertTitle>
                    <AlertTitle>Email: {item.user?.email}</AlertTitle>
                    <AlertTitle>Лицевой счет: {item.lic}</AlertTitle>
                    <AlertTitle>Дата обращения: {dayjs(item.createdDate).format('DD.MM.YYYY')}</AlertTitle>
                    {item.status.id == Status.InWork && <AlertTitle>В работе у оператор: {item.operatorUser.fio}</AlertTitle>}
                  </Alert>
                  </Link>
                  {item.status.id == Status.Create &&
                  <Button
                    variant="contained"
                    color="success"
                    sx={{ alignItems: 'right', borderRadius: 'sm' }}
                    //endDecorator={<SendRoundedIcon />}
                    onClick={()=>{
                      dispatch(OperatorTakeOnJob({requestId:item.requestId})).then(()=>{
                        dispatch(GetUserRequestOperatorFilter({take:'1',skip:(selectPage * pageSizeInfo).toString(),statusId:selectStatusId.toString(),lic:selectLic}));;
                      });
                      
                    }}
                  >
                    Взять заявку в работу
                  </Button>
                  }
                  {switchJob &&
                    <Button
                      variant="contained"
                      color="success"
                      sx={{ alignItems: 'right', borderRadius: 'sm', marginLeft:'20px' }}
                      //endDecorator={<SendRoundedIcon />}
                      onClick={()=>{
                        dispatch(SwitchJob({requestId:item.requestId, userId:selectUserInfo.userId})).then(()=>{
                          dispatch(GetUserRequestOperatorFilter({take:pageSizeInfo.toString(),skip:(0 * pageSizeInfo).toString(),statusId:selectStatusId.toString(),lic:selectLic}));;
                        });
                        
                      }}
                    >
                      Назначить на оператор
                    </Button>
                  }
                  {(userIsRole(userInfo,UserRole.OperatorAdmin) || userIsRole(userInfo,UserRole.Admin)) &&
                    <Button
                      variant="contained"
                      color="error"
                      sx={{ alignItems: 'right', borderRadius: 'sm', marginLeft:'20px' }}
                      //endDecorator={<SendRoundedIcon />}
                      onClick={()=>{
                        dispatch(RemoveRequest({requestId:item.requestId})).then(()=>{
                          dispatch(GetUserRequestOperatorFilter({take:pageSizeInfo.toString(),skip:(selectPage * pageSizeInfo).toString(),statusId:selectStatusId.toString(),lic:selectLic}));;
                        });
                        
                      }}
                    >
                      Удалить
                    </Button>
                  }
                  </>
                  );
            })}
            {(userRequest != undefined && userRequest != null && userRequest[0]?.count > pageSizeInfo) && 
              <Stack style={{margin:'auto',width:'50%'}} spacing={2}>
                <Pagination count={Math.ceil(userRequest[0]?.count / pageSizeInfo)} color="primary" page={selectPage+1} onChange={(event, pageNumber)=>{
                  setSelectPage(pageNumber-1);
                  setSelectPageTop(pageNumber-1);
                }} />
              </Stack>
            }
          </Grid>
          <Grid item {...{ xs: 12, sm: 3, md: 3, lg: 3 }} >
            <Typography variant='body1' style={{textAlign:'center', fontWeight:500}} >Поиск заявок</Typography>
            <FormControl fullWidth style={{marginTop:'10px'}} >
                <InputLabel id="demo-simple-select-label">Тема</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectStatusId}
                  label="Age"
                  onChange={(item:any)=>{
                    setSelectStatusId(item.target.value);
                  }}
                >
                  {status?.map((item, index)=>{
                    if((item.status == 'В работе') && (userIsRole(userInfo,UserRole.OperatorAdmin)|| userIsRole(userInfo,UserRole.Admin)))
                      return (<MenuItem value={item.id}>{item.status}</MenuItem>)
                    if((item.status == 'Новые' || item.status == 'Мои заявки в работе' || item.status == 'Выполнено'|| item.status == 'Отменено') && (userIsRole(userInfo,UserRole.OperatorAdmin) || userIsRole(userInfo,UserRole.Operator) || userIsRole(userInfo,UserRole.Admin)))
                      return (<MenuItem value={item.id}>{item.status}</MenuItem>)
                  })}
                </Select>
                <TextField
                  id="outlined-number"
                  label=""
                  type="text"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  helperText={`Введите Лицевой счет`}
                  onChange={(e:any)=>{
                    setSelectPage(1-1);
                    setSelectPageTop(1-1);
                    setSelectLic(e.target.value);
                    //setSelectUserInfo({...selectUserInfo, lic:e.target.value})
                  }}
                />
                 <FormControlLabel
                    control={
                      <Checkbox checked={switchJob} onChange={(e:any)=>{
                        setSwitchJob(e.target.checked);
                      }} name="jason" />
                    }
                    label="Назначить на оператора"
                  />
                {switchJob &&
                  <Autocomplete
                    disablePortal
                    noOptionsText={'Ничего не найдено'}
                    id="combo-box-demo"
                    options={searchUser ?? []}
                    onChange={(event, newValue) => {
                      setSelectUserInfo({...selectUserInfo, userId:newValue?.id ?? '', fio:newValue?.label ?? ''})
                    }}
                    onInputChange={(event: object, value: any)=>{
                      setSearchValue(value);
                    }}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="Пользователь" />}
                  />
                }
              </FormControl>
          </Grid>
        </Grid>
      </Container>
  </>
  );
}

function Getseverity(id:number){
  if(id === 0 || id === 1)
    return 'info';
  if(id === 2)
    return 'error';
  if(id === 3)
    return 'success';
  if(id === 4)
    return 'warning';

  return 'info';
}

