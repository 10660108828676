import { Box, Container, createTheme, Grid, Toolbar } from "@mui/material";
import { useParams } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import useWindowDimensions from "../../Hooks/windowDimensions";
import { CssBaseline, CssVarsProvider } from "@mui/joy";
import MessagesPane from "./messages/MessagesPane";
const defaultTheme = createTheme();

export default function UserRequestChat() {
  const { id } = useParams();
  console.log(id);
  const { height, width } = useWindowDimensions();
  return (
    <>
      <ThemeProvider theme={defaultTheme}>
        <Box sx={{ display: "flex" }}>
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) => (theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[900]),
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Toolbar />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }} style={width >= 600 ? { paddingLeft: "70px" } : { paddingLeft: "12px" }}>
              <Grid container spacing={0}>
                <Grid item {...{ xs: 12, sm: 12, md: 12, lg: 12 }}>
                  <CssVarsProvider disableTransitionOnChange>
                    <CssBaseline />
                    <Box sx={{ display: "flex", minHeight: "100dvh" }}>
                      <Box component="main" className="MainContent" sx={{ flex: 1 }}>
                        <MessagesPane chatId={id} />
                      </Box>
                    </Box>
                  </CssVarsProvider>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
}
