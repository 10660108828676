import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

import HistoryIndicationTable from "../../Components/table/history-indication-table/history-indication-table";
import HistoryIndicationGraphics from "../../Components/graphics/history-indication-graphics/history-indication-graphics";
import useWindowDimensions from "../../Hooks/windowDimensions";

import dayjs, { Dayjs } from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import { ruRU } from "@mui/x-date-pickers/locales";
import LocalStorageProvider from "../../utils/locaStorageProvider";
import { LocalStorageMethod } from "../../enums/enum";
import { useAppDispatch, useAppSelector } from "../../Hooks";
import { getUserInfo } from "../../store/user-process/selector";
import { getPaymentHistory, getPersonalInfo, getReadingHistory } from "../../store/integration-process/selector";
import CardValueData from "../../Components/card-value-date/card-value-date";
import { TextField } from "@mui/material";
import { GetPayments, GetReadingHistory } from "../../services/api-actions/integration-action";
import { Console } from "console";
import LicDialog from "../../Components/dialog/lic/LicDialog";
import AddIndinications from "../../Components/dialog/lic/AddIndinications";
import HistoryPaymentTable from "../../Components/table/history-payment-table/history-payment-table";
import HistoryPaymentGraphics from "../../Components/graphics/history-payment-graphics/history-payment-graphics";

const defaultTheme = createTheme();

export default function HistoryPayment() {
  const dispatch = useAppDispatch();
  const userInfo = useAppSelector(getUserInfo);
  let defaultLic = LocalStorageProvider(userInfo.userId, null, LocalStorageMethod.getItem);
  React.useEffect(() => {
    if (defaultLic != "") {
      dispatch(GetPayments({ fullLic: defaultLic.lic }));
    }
  }, [defaultLic.lic]);
  const paymentHisory = useAppSelector(getPaymentHistory);

  const [openIndinicationDialog, setOpenIndinicationDialog] = React.useState(false);

  const { height, width } = useWindowDimensions();
  let MinWidth = width < 800 ? "10px" : "230px";
  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: "flex" }}>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) => (theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[900]),
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }} style={width >= 600 ? { paddingLeft: "70px" } : { paddingLeft: "12px" }}>
            <Grid container spacing={2}>
              {paymentHisory?.length > 0 && (
                <>
                  <Grid item xs={12} md={4} lg={3}>
                    <Paper
                      sx={{
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                        height: 240,
                      }}
                    >
                      <CardValueData title="Сумма последнего платежа" date="2024-01-31" value={paymentHisory[0]?.transactionAmount} />
                    </Paper>
                  </Grid>

                  <Grid item xs={12} md={8} lg={9}>
                    <Paper
                      sx={{
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                        height: 240,
                      }}
                    >
                      <HistoryPaymentGraphics data={paymentHisory} />
                    </Paper>
                  </Grid>

                  <Grid item xs={12} style={{ marginBottom: "40px" }}>
                    <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                      <HistoryPaymentTable data={paymentHisory} />
                    </Paper>
                  </Grid>
                </>
              )}
            </Grid>
            <LicDialog Open={openIndinicationDialog} Close={setOpenIndinicationDialog} Title="Внести показания" Component={<AddIndinications />} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
function toJSONLocal(date: Date) {
  var local = new Date(date);
  local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  return local.toJSON().slice(0, 10);
}
