import {createSlice} from '@reduxjs/toolkit';
import { NameSpace } from '../../enums/enum';
import { IRoles, IShowAlert, IUserInfo, dataUser } from '../../types/user-info/user-info';
import { GetAllRoles, GetUserInfo, SearchUserFio } from '../../services/api-actions/user-action';
import { UserRequest } from '../../types/user-info/user-request';
import { GetUserRequestAll, GetUserRequestAllOperrator, GetUserRequestMessage, GetUserRequestOperatorFilter } from '../../services/api-actions/personal-user-request-action';
import { IUserMessage } from '../../types/user-info/user-message';

const initialState: dataUser = {
   userInfo: {} as IUserInfo,
   selectLic: '',
   showAlert: {} as IShowAlert,
   searchUserByFio: [] as IUserInfo[],
   roles: [] as IRoles[],
   userRequest: [] as UserRequest[],
   userMessage: [] as IUserMessage[],
};

export const dataUsers = createSlice({
  name: NameSpace.User,
  initialState,
  reducers: {
    userInfo: (state,action)=>{
      state.userInfo = action.payload;
    },
    setSelectedLic: (state,action)=>{
      state.selectLic = action.payload;
    },
    setShowAllert: (state,action)=>{
      state.showAlert = action.payload;
    },
    addUserMessage: (state,action)=>{
      state.userMessage.push(action.payload);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(GetUserInfo.fulfilled, (state, action) => {
        state.userInfo = action.payload;
      })
      .addCase(SearchUserFio.fulfilled, (state, action) => {
        state.searchUserByFio = action.payload;
      })
      .addCase(GetAllRoles.fulfilled, (state, action) => {
        state.roles = action.payload;
      })
      .addCase(GetUserRequestAll.fulfilled, (state, action) => {
        state.userRequest = action.payload;
      })
      .addCase(GetUserRequestOperatorFilter.fulfilled, (state, action) => {
        state.userRequest = action.payload;
      })
      .addCase(GetUserRequestAllOperrator.fulfilled, (state, action) => {
        state.userRequest = action.payload;
      })
      .addCase(GetUserRequestMessage.fulfilled, (state, action) => {
        state.userMessage = action.payload;
      })
  }
});

export const {userInfo,setSelectedLic,setShowAllert,addUserMessage} = dataUsers.actions;