import { Button, Card, CardActions,  CardContent, CardMedia, TextField, Typography } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import React, { useState } from "react";
import ApiAuthorzationRoutes from '../../api-authorization/ApiAuthorizationRoutes';
import { useAppDispatch, useAppSelector } from "../../../Hooks";
import { AddFullLicAtAccount, AddQueueReceipt, GetUserInfo } from "../../../services/api-actions/user-action";
import { getUserInfo } from "../../../store/user-process/selector";
import useWindowDimensions from "../../../Hooks/windowDimensions";
import AuthorizeRoute from "../../api-authorization/AuthorizeRoute";
import Main from "../../../Page/Main/Main";
import ShowAlert from "../../allert/alert";
import { Link } from "react-router-dom";
import { AppRoute, LocalStorageMethod } from "../../../enums/enum";
import LocalStorageProvider from "../../../utils/locaStorageProvider";

export default function QueryReceipt(){
  const { height, width } = useWindowDimensions();
  let MinWidth = width < 800 ? '300px' : '230px';
  const [dates,setDates] = useState({
    startDate: toJSONLocal(new Date()),
    endDate: toJSONLocal(new Date()),
  });
  const userInfo = useAppSelector(getUserInfo);
  const dispatch = useAppDispatch();
 // React.useEffect(() => {
   // localStorage.setItem('token', JSON.stringify(accessToken));
 //   dispatch(GetUserInfo());
 // }, [dispatch]);
  return(
  <>
    <Card>
      <CardMedia style={{padding: '10px'}}>
      <p>Заполните дату с и по которой вам надо квитанций</p>
        <TextField
          style={{marginTop:"20px", minWidth: MinWidth }}
          id="IndicationDate"
          label="Дата с"
          type="month"
          InputLabelProps={{ shrink: true }}
          onInput={(e:any)=>{
            setDates({startDate: toJSONLocal(new Date(e.target.value + '-01')), endDate: dates.endDate});
          }}
          defaultValue={ toJSONLocal(new Date())}
        />
        <TextField
          style={{marginTop:"20px", minWidth: MinWidth }}
          id="IndicationDate"
          label="Дата по"
          type="month"
          InputLabelProps={{ shrink: true }}
          onInput={(e:any)=>{
           
           setDates({startDate: dates.startDate, endDate: toJSONLocal(new Date(e.target.value + '-01'))});
          }}
          defaultValue={ toJSONLocal(new Date())}
        />
      </CardMedia>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            <Button variant="contained" color="success" endIcon={<SendIcon />}
              onClick={()=>{
                dispatch(AddQueueReceipt({Email:userInfo.email,
                  fullLic:LocalStorageProvider(userInfo.userId,null,LocalStorageMethod.getItem)?.lic,
                  startDate:dates.startDate, 
                  endDate :dates.endDate}));
              }}
            >
              Отправить запрос
            </Button>
          </Typography>
        </CardContent>
      <CardActions>
      </CardActions>
    </Card>
  </>
  );
}
function toJSONLocal(date:Date) {
  var local = new Date(date);
  local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  return local.toJSON().slice(0, 10);
}
