import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Badge, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Grid } from "@mui/material";
import { useAppSelector } from "../../Hooks";
import { getUserInfo } from "../../store/user-process/selector";
import browserHistory from "../../history";
import { AppRoute } from "../../enums/enum";
import React, { useState } from "react";
import CarouselMain from "../../Components/carousel/carousel-main";
import AddLicDialog from "../../Components/dialog/lic/LicDialog";
import AddLic from "../../Components/dialog/lic/AddLic";
import AddIndinications from "../../Components/dialog/lic/AddIndinications";
import LicDialog from "../../Components/dialog/lic/LicDialog";
import { Link } from "react-router-dom";
import QueryReveipt from "../../Components/dialog/lic/QueryReceipt";
import { FetchData } from "../../FetchData";
import useWindowDimensions from "../../Hooks/windowDimensions";
import AppRoutes from "../../AppRoutes";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function MiniDrawer() {
  const [openDialogAddLic, setOpenDialogAddLic] = useState(false);
  const [openIndinicationDialog, setOpenIndinicationDialog] = useState(false);
  const [openKvitDialog, setOpenKvitDialog] = useState(false);
  const userInfo = useAppSelector(getUserInfo);
  if (userInfo.lics?.length === 0) {
    window.location.href = AppRoute.Lic;
  }
  const colWidth = { xs: 12, sm: 12, md: 6, lg: 6 } as const;
  const { height, width } = useWindowDimensions();
  let themMain;
  let marginTop = "";
  let marginBottom = "";
  if (width > 600) {
    themMain = { flexGrow: 1, p: 8 };
    marginTop = "20px";
    marginBottom = "20px";
  } else {
    themMain = {};
    marginTop = "60px";
    marginBottom = "60px";
  }
  return (
    <>
      <Box sx={themMain} style={{ marginTop: marginTop, marginBottom: marginBottom }}>
        {/* <CarouselMain/> */}
        {userInfo.fio && (
          <Grid container spacing={2}>
            <Grid item {...{ xs: 12, sm: 12, md: 12, lg: 12 }}>
              <Card style={{ textAlign: "center" }}>
                <CardActionArea>
                  <CardMedia />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      ЛИЧНЫЙ КАБИНЕТ КЛИЕНТА
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      В личном кабинете доступны следующие операции
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item {...colWidth}>
              <Card style={{ border: "2px solid #9cdd9a" }}>
                <CardActionArea
                  onClick={(e: any) => {
                    window.location.href = AppRoute.Lic;
                  }}
                >
                  <CardMedia />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      <Badge
                        badgeContent={userInfo.lics?.length}
                        color="primary"
                        onClick={(e: any) => {
                          window.location.href = AppRoute.Lic;
                        }}
                      >
                        Мои лицевые счета
                      </Badge>
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Добавить к управлению дополнительные лицевые счета.
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Button
                    size="small"
                    color="primary"
                    onClick={(e: any) => {
                      setOpenDialogAddLic(true);
                    }}
                  >
                    Добавить
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            {userInfo.lics?.length !== 0 && (
              <Grid item {...colWidth}>
                <Card style={{ border: "2px solid #9cdd9a" }}>
                  <CardActionArea
                    onClick={(e: any) => {
                      setOpenKvitDialog(true);
                    }}
                  >
                    <CardMedia />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        Архив квитанций
                      </Typography>
                      <Typography variant="body2" color="textSecondary" component="p">
                        Вы можете запросить квитанции за прошлые месяца.
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button
                      size="small"
                      color="primary"
                      onClick={(e: any) => {
                        setOpenKvitDialog(true);
                      }}
                    >
                      Запросить
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            )}
            {userInfo.lics?.length !== 0 && (
              <Grid item {...colWidth}>
                <Card style={{ border: "2px solid #9cdd9a" }}>
                  <CardActionArea
                    onClick={(e: any) => {
                      window.location.href = AppRoute.Counter;
                    }}
                  >
                    <CardMedia />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        Приборы учета
                      </Typography>
                      <Typography variant="body2" color="textSecondary" component="p">
                        Информация по приборам учета
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button
                      size="small"
                      color="primary"
                      onClick={(e: any) => {
                        window.location.href = AppRoute.Counter;
                      }}
                    >
                      Просмотреть
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            )}
            {userInfo.lics?.length !== 0 && (
              <Grid item {...colWidth}>
                <Card style={{ border: "2px solid #9cdd9a" }}>
                  <CardActionArea
                    onClick={(e: any) => {
                      window.location.href = AppRoute.SendReadings;
                    }}
                  >
                    <CardMedia />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        Передача показаний
                      </Typography>
                      <Typography variant="body2" color="textSecondary" component="p">
                        Здесь вы можете передать показания
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button
                      size="small"
                      color="primary"
                      onClick={(e: any) => {
                        window.location.href = AppRoute.SendReadings;
                      }}
                    >
                      Передать показания
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            )}
            <Grid item {...colWidth}>
              <Card style={{ border: "2px solid #9cdd9a" }}>
                <CardActionArea
                  onClick={(e: any) => {
                    window.location.href = AppRoute.UserRequests;
                  }}
                >
                  <CardMedia />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      Мои обращения
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Здесь вы можете просмотреть ваши обращения в Т+
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Button
                    size="small"
                    color="primary"
                    onClick={(e: any) => {
                      window.location.href = AppRoute.UserRequests;
                    }}
                  >
                    Просмотреть
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            {userInfo.lics?.length !== 0 && (
              <Grid item {...colWidth}>
                <Card style={{ border: "2px solid #9cdd9a" }}>
                  <CardActionArea
                    onClick={(e: any) => {
                      window.location.href = AppRoute.HistoryPayment;
                    }}
                  >
                    <CardMedia />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        История оплат
                      </Typography>
                      <Typography variant="body2" color="textSecondary" component="p">
                        Здесь вы можете просмотреть историю оплат
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button
                      size="small"
                      color="primary"
                      onClick={(e: any) => {
                        window.location.href = AppRoute.HistoryPayment;
                      }}
                    >
                      Просмотреть
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            )}
            <Grid item {...{ xs: 12, sm: 12, md: 12, lg: 12, justifyContent: "center", textAlign: "center" }}>
              <a href="https://penza.esplus.ru" style={{ color: "black" }}>
                перейти на сайт Пензенского филиала Энергосбыт Т Плюс
              </a>
            </Grid>
            <LicDialog Open={openIndinicationDialog} Close={setOpenIndinicationDialog} Title="Внести показания" Component={<AddIndinications />} />
            <AddLic Open={openDialogAddLic} Close={setOpenDialogAddLic} Title="Добавить лицевой счет" />
            <LicDialog Open={openKvitDialog} Close={setOpenKvitDialog} Title="Архив квитанций" Component={<QueryReveipt />} />
          </Grid>
        )}
      </Box>
    </>
  );
}
